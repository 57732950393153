<template>
  <!-- TO DO: translate -->
  <NResult status="404" title="Oops! We can't find that page." description="You know life is sometimes ridiculous.">
    <template #footer>
      <RouterLink v-slot="{ navigate }" to="/projects">
        <NButton type="primary" @click="navigate">Take me to the Projects</NButton>
      </RouterLink>
    </template>
  </NResult>
</template>

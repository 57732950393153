/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type OverallSentimentInsightElementUpdateRequest = {
  kind?: 'OverallSentimentInsight';
  order_id?: number;
  visible_left?: OverallSentimentInsightElementUpdateRequest.visible_left;
  visible_right?: OverallSentimentInsightElementUpdateRequest.visible_right;
};
export namespace OverallSentimentInsightElementUpdateRequest {
  export enum visible_left {
    SENTIMENT_SCORE_GAUGE = 'sentiment_score_gauge',
    SENTIMENT_SCORE_SEGMENTED_BAR_CHART = 'sentiment_score_segmented_bar_chart',
  }
  export enum visible_right {
    SENTIMENT_SCORE_OVER_TIME_LINE = 'sentiment_score_over_time_line',
    SENTIMENT_SCORE_OVER_TIME_BAR = 'sentiment_score_over_time_bar',
  }
}


/* re-usable translation helpers */

import { tolgee } from '@/plugins/i18n'

// if it is a built in role, get translated role name, get custom name if not
export const getRoleTranslation = (roleName = '') => {
  const isCustom = !tolgee.t(`team.roles.${roleName}.name`, '')
  const name = !isCustom ? tolgee.t(`team.roles.${roleName}.name`) : roleName
  const description = !isCustom
    ? tolgee.t(`team.roles.${roleName}.description`)
    : tolgee.t(`team.roles.custom.description`)

  return { name, description }
}

// if it is a built in permission, get translated permission name, get custom name if not
export const getProjectPermissionTranslation = (permissionName = '') => {
  const isCustom = !tolgee.t(`projects.settings_view.permissions.permission_types.${permissionName}.name`, '')
  const name = !isCustom
    ? tolgee.t(`projects.settings_view.permissions.permission_types.${permissionName}.name`)
    : permissionName

  const description = !isCustom
    ? tolgee.t(`projects.settings_view.permissions.permission_types.${permissionName}.description`)
    : tolgee.t(`projects.settings_view.permissions.permission_types.custom.description`)

  return { name, description }
}

<template>
  <NSpin :show="loading">
    <NDatePicker
      ref="datePickerRef"
      v-model:value="internalDate"
      panel
      type="daterange"
      :actions="null"
      class="my-0"
      v-bind="$attrs"
      @update:value="handleDateUpdate"
    >
      <template #footer>
        <NButton
          v-for="shortcut in customShortcuts"
          :key="shortcut.text"
          size="tiny"
          class="mr-2"
          :type="shortcut.selected ? 'primary' : undefined"
          @click="shortcut.click"
          @mouseover="shortcut.mouseOver"
          @mouseout="shortcut.mouseLeave"
        >
          {{ shortcut.text }}
        </NButton>
      </template>
    </NDatePicker>
  </NSpin>
</template>

<script lang="ts">
import { type PropType, defineComponent, onMounted, ref, watch } from 'vue'
import { isEqual } from 'lodash-es'
import { useTranslate } from '@tolgee/vue'
import type { NDatePicker } from 'naive-ui'

type TUseShortcutArgs = {
  text: string
  start?: number
  end?: number
}

type TShortcut = {
  text: string
  selected: boolean
  click: () => void
  mouseOver?: () => void
  mouseLeave?: () => void
}

const DatePickerPanel = defineComponent({
  name: 'DatePickerPanel',
  inheritAttrs: false,
  props: {
    value: {
      type: Array as PropType<number[]>,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const { t } = useTranslate()
    const datePickerRef = ref()
    const internalDate = ref()
    const startDate = ref()
    const endDate = ref()
    const today = new Date()

    const useShortcut = ({ text = 'Custom shortcut', start, end }: TUseShortcutArgs): TShortcut => {
      const value = start && end ? [start, end] : undefined

      return (() => ({
        text,
        get selected() {
          return isEqual(props.value, value)
        },
        click: () => {
          datePickerRef.value.panelInstRef.clearPendingValue()
          handleDateUpdate(value)
        },
        mouseOver: () => datePickerRef.value.panelInstRef.handleRangeShortcutMouseenter(value),
        mouseLeave: () => datePickerRef.value.panelInstRef.handleShortcutMouseleave(),
      })).call(this)
    }

    const customShortcuts: TShortcut[] = [
      // simple shortcut
      {
        text: t.value('datetime.all_time'),
        click: () => datePickerRef.value.panelInstRef.handleClearClick(),
        get selected() {
          return !props.value
        },
      },
      useShortcut({
        text: t.value('datetime.last_30_days'),
        start: new Date(new Date(new Date().setDate(today.getDate() - 30))).getTime(),
        end: today.getTime(),
      }),
      useShortcut({
        text: t.value('datetime.this_month'),
        start: new Date(today.getFullYear(), today.getMonth(), 1).getTime(),
        end: today.getTime(),
      }),
      useShortcut({
        text: t.value('datetime.past_month'),
        start: new Date(today.getFullYear(), today.getMonth() - 1, 1).getTime(),
        end: new Date(today.getFullYear(), today.getMonth(), 0).getTime(),
      }),
      useShortcut({
        text: t.value('datetime.this_year'),
        start: new Date(today.getFullYear(), 0, 1).getTime(),
        end: new Date(today.getFullYear(), 11, 31).getTime(),
      }),
      useShortcut({
        text: t.value('datetime.past_year'),
        start: new Date(today.getFullYear() - 1, 0, 1).getTime(),
        end: new Date(today.getFullYear() - 1, 11, 31).getTime(),
      }),
    ]

    // emits only after the second selection
    const handleDateUpdate = (val: Array<number> | undefined) => {
      if (!val) {
        emit('update:value', undefined)
        if (datePickerRef.value.panelInstRef) {
          datePickerRef.value.panelInstRef.isSelecting = false
        }

        return
      }

      const [start, end] = val

      if (datePickerRef.value.panelInstRef.isSelecting) {
        // isSelecting true, this is the first click
        startDate.value = start
        return
      }

      // isSelecting false, this is the second click
      // emit update to parent
      endDate.value = end
      emit('update:value', [start, end])
    }

    watch(
      () => props.value,
      (newVal) => {
        if (!newVal) {
          internalDate.value = undefined
          datePickerRef.value.handleRangeUpdateValue(['', ''], {})
          return
        }

        internalDate.value = newVal
        datePickerRef.value.handleRangeUpdateValue(
          [new Date(newVal[0]).toISOString(), new Date(newVal[1]).toISOString()],
          {}
        )

        if (datePickerRef.value.panelInstRef) datePickerRef.value.panelInstRef.isSelecting = false
      }
    )

    onMounted(() => {
      if (props.value) {
        internalDate.value = props.value
      }
    })

    return {
      datePickerRef,
      internalDate,
      handleDateUpdate,
      customShortcuts,
    }
  },
})

// TO DO: find out if this is possible to do in setup syntax.
// leaving it as is unless we find a solution
export default DatePickerPanel as typeof DatePickerPanel & Omit<typeof NDatePicker, 'value'>
</script>

<style lang="scss" scoped></style>

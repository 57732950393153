/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NpsScoreInsightElementUpdateRequest = {
  kind?: 'NPSScoreInsight';
  order_id?: number;
  visible_left?: NpsScoreInsightElementUpdateRequest.visible_left;
  visible_right?: NpsScoreInsightElementUpdateRequest.visible_right;
};
export namespace NpsScoreInsightElementUpdateRequest {
  export enum visible_left {
    NPS_SCORE_GAUGE = 'nps_score_gauge',
    NPS_SCORE_PIE = 'nps_score_pie',
    NPS_SCORE_SEGMENTED_BAR = 'nps_score_segmented_bar',
  }
  export enum visible_right {
    NPS_OVER_TIME_LINE = 'nps_over_time_line',
  }
}


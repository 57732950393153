/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TextToAnalyzeMetadataUIResponse } from './TextToAnalyzeMetadataUIResponse';
import type { TopicUIResponse } from './TopicUIResponse';
export type TextToAnalyzeFieldUIResponse = {
  /**
   * Human-readable identifier for this column. The reference field is immutable and is unique among all columns within the same project.
   */
  ref: string;
  /**
   * Human-readable name for this column.
   */
  name: string;
  /**
   * Boolean indicating whether column is set as favorite.
   */
  is_favorite: boolean;
  /**
   * Type of this column.
   */
  type: TextToAnalyzeFieldUIResponse.type;
  /**
   * Column description displayed for this column.
   */
  description?: string;
  /**
   * List of topics associated with this column.
   */
  topics?: Array<TopicUIResponse>;
  /**
   * Metadata associated with this column.
   */
  metadata?: TextToAnalyzeMetadataUIResponse;
};
export namespace TextToAnalyzeFieldUIResponse {
  /**
   * Type of this column.
   */
  export enum type {
    TEXT_TO_ANALYZE = 'text_to_analyze',
  }
}


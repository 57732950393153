/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LearnsFrom } from './LearnsFrom';
export type Metadata = {
  learns_from?: LearnsFrom;
  category?: Metadata.category;
  do_group_duplicates?: boolean;
  do_show_translations?: boolean;
  question_show_sentiment?: boolean;
};
export namespace Metadata {
  export enum category {
    CUSTOMER_SATISFACTION = 'customer_satisfaction',
    EMPLOYEE_FEEDBACK = 'employee_feedback',
    BRAND_PERCEPTION = 'brand_perception',
    PRODUCT_PERCEPTION = 'product_perception',
    EVENT_EVALUATION = 'event_evaluation',
    LIST_ANSWERS = 'list_answers',
    OTHER = 'other',
  }
}


<template>
  <NTag
    round
    class="custom-tag-item shadow-sm"
    size="small"
    :class="{
      'cursor-pointer': clickable,
    }"
  >
    <NBadge dot :color="tagColor?.textColor" />
    {{ tag }}
    <FaIcon v-if="closable" icon="far fa-close" size="sm" class="cursor-pointer" @click.stop="$emit('close')" />
    <slot />
  </NTag>
</template>

<script lang="ts" setup>
import { type TColorNames, getTagColor, getTagColorFromString } from '@/utils/color'
import { computed } from 'vue'

interface ITagItemProps {
  tag: string
  // we can simply use hex or rgb or tailwind color names here
  // TColorNames is for auto completion purposes
  color?: string | TColorNames
  closable?: boolean
  clickable?: boolean
}

const props = withDefaults(defineProps<ITagItemProps>(), {
  clickable: true,
})

defineEmits<{ close: [] }>()

const tagColor = computed(() => (props.color ? getTagColor(props.color) : getTagColorFromString(props.tag)))
</script>

<style lang="scss" scoped>
.custom-tag-item {
  :deep(.n-tag__content) {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &.fill-content-width {
    :deep(.n-tag__content) {
      width: 100%;
    }
  }
}
</style>

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LoginRequest } from '../models/LoginRequest';
import type { LoginResponse } from '../models/LoginResponse';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { SamlRedirectResponse } from '../models/SamlRedirectResponse';
import type { SetPasswordRequest } from '../models/SetPasswordRequest';
import type { SudoModeRequest } from '../models/SudoModeRequest';
import type { SudoModeTokenResponse } from '../models/SudoModeTokenResponse';
import type { UserUpdatePasswordRequest } from '../models/UserUpdatePasswordRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
  /**
   * Logout
   * Allows Users to log out.
   * @returns any OK
   * @throws ApiError
   */
  public static logout(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/logout',
    });
  }
  /**
   * Update the current user's password
   * Update the current user's password.
   * @returns string OK
   * @throws ApiError
   */
  public static userPasswordUpdate({
    requestBody,
  }: {
    requestBody: UserUpdatePasswordRequest,
  }): CancelablePromise<Record<string, string>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/user/password-change/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Unprocessable Entity`,
      },
    });
  }
  /**
   * Retrieve a temporary sudo-mode token.
   * Elevate privileges by obtaining a sudo-mode token using your account password or second factor.
   * @returns SudoModeTokenResponse OK
   * @throws ApiError
   */
  public static sudoMode({
    requestBody,
  }: {
    requestBody: SudoModeRequest,
  }): CancelablePromise<SudoModeTokenResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/sudo-mode/',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Unprocessable Entity`,
      },
    });
  }
  /**
   * Check if Sudo Mode Token is still valid.
   * @returns void
   * @throws ApiError
   */
  public static sudoModeStatus(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/auth/sudo-mode/',
    });
  }
  /**
   * Verify a new email address.
   * Verify a new email address.
   * @returns SamlRedirectResponse OK
   * @throws ApiError
   */
  public static userVerifyEmail({
    key,
  }: {
    key: string,
  }): CancelablePromise<SamlRedirectResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/verify-email/{key}/',
      path: {
        'key': key,
      },
    });
  }
  /**
   * Request a password reset email.
   * Allow users to reset their passwords via email.
   *
   * An empty response means the request was successful and an email was sent.
   * @returns void
   * @throws ApiError
   */
  public static userResetPasswordRequest({
    requestBody,
  }: {
    requestBody: ResetPasswordRequest,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/reset-password/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Perform the password reset with a valid token.
   * Allow users to reset their passwords via email.
   *
   * An empty response means the request was successful and an email was sent.
   * @returns void
   * @throws ApiError
   */
  public static userResetPassword({
    token,
    requestBody,
  }: {
    token: string,
    requestBody: SetPasswordRequest,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/auth/reset-password/{token}/',
      path: {
        'token': token,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Login
   * Allows Users to log in, sets cookies if successful.
   * @returns LoginResponse OK
   * @throws ApiError
   */
  public static login({
    requestBody,
  }: {
    requestBody: LoginRequest,
  }): CancelablePromise<LoginResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}

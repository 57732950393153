import {
  AvailableWidgetId,
  ColumnKind,
  type TopicTrendTable,
  type TopicTrendsNegativeTableInsightElementResponse,
  type TopicTrendsPositiveTableInsightElementResponse,
  WidgetKind,
} from '@/api'
import { theme as uno } from '@unocss/preset-mini'

export const MOCK_SEGMENT_ID = 'MOCK_SEGMENT_ID'

export const topicTrendsPositiveTableInsightElementMock: TopicTrendsPositiveTableInsightElementResponse = {
  id: 'ie_1',
  kind: 'TopicTrendsPositiveTableInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  trending_data: {
    id: 'wg_1',
    kind: WidgetKind.TOPIC_TRENDS_POSITIVE_TABLE,
    name: 'Positive Trending',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.TRENDING_DATA,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  filters: [],
  available_filters: {},
}

export const topicTrendsNegativeTableInsightElementMock: TopicTrendsNegativeTableInsightElementResponse = {
  id: 'ie_2',
  kind: 'TopicTrendsNegativeTableInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  trending_data: {
    id: 'wg_1',
    kind: WidgetKind.TOPIC_TRENDS_NEGATIVE_TABLE,
    name: 'Negative Trending',
    insight_element_id: 'ie_2',
    available_identifier: AvailableWidgetId.TRENDING_DATA,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  filters: [],
  available_filters: {},
}

const randomRange = (min = -100, max = 100) => Math.floor(Math.random() * (max - min + 1)) + min
const getRandomDataPointObject = (isChangeValue = false) => ({
  score: randomRange(isChangeValue ? -100 : 0, 100) / 100,
  row_count: randomRange(isChangeValue ? -1000 : 0, 1000),
  row_count_relative: randomRange(isChangeValue ? -100 : 0, 100) / 100,
})

const colors = [uno.colors.amber[300], uno.colors.fuchsia[300], uno.colors.amber[300]]
const getColorIndex = () => Math.floor(Math.random() * colors.length)

const createTopicTrendsTable = (): TopicTrendTable['results'] => {
  return {
    [MOCK_SEGMENT_ID]: {
      rows: [
        {
          id: 'row_1',
          category: 'CREATIVE',
          label: 'Legacy',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
        {
          id: 'row_2',
          category: 'USABILITY',
          label: 'Internal',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
        {
          id: 'row_3',
          category: 'DIVISION',
          label: 'Human',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
        {
          id: 'row_4',
          category: 'SOLUTIONS',
          label: 'Product',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
        {
          id: 'row_5',
          category: 'IMPLEMENTATION',
          label: 'District',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
        {
          id: 'row_6',
          category: 'PRICE',
          label: 'Price / cost',
          values: new Array(5).fill('').map(() => getRandomDataPointObject()),
          value: getRandomDataPointObject(),
          change: getRandomDataPointObject(true),
          color: colors[getColorIndex()],
          p_value: String(Math.random()),
        },
      ],
      tick_labels: ['Q1 2021', 'Q2 2021', 'Q3 2021', 'Q4 2021', 'Q1 2022'],
      timeframe: '3 months',
    },
  }
}

// Positive table widget
export const topicTrendsTableMock: TopicTrendTable = {
  kind: 'topic-trends-data-table',
  results: createTopicTrendsTable(),
}

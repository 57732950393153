/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type SelectedRowsInsightElementUpdateRequest = {
  kind?: 'SelectedRowsInsight';
  order_id?: number;
  visible_left?: SelectedRowsInsightElementUpdateRequest.visible_left;
  visible_center?: SelectedRowsInsightElementUpdateRequest.visible_center;
  visible_right?: SelectedRowsInsightElementUpdateRequest.visible_right;
};
export namespace SelectedRowsInsightElementUpdateRequest {
  export enum visible_left {
    PROMOTERS_ROWS = 'promoters_rows',
    PROMOTERS_SUMMARY = 'promoters_summary',
  }
  export enum visible_center {
    PASSIVES_ROWS = 'passives_rows',
    PASSIVES_SUMMARY = 'passives_summary',
  }
  export enum visible_right {
    DETRACTORS_ROWS = 'detractors_rows',
    DETRACTORS_SUMMARY = 'detractors_summary',
  }
}


<template>
  <div class="relative">
    <div v-if="!hideLabels" class="mb-2">
      <label class="font-medium">{{ $t('projects.topics_view.applied_topics') }} {{ value.length }}</label>
    </div>
    <div v-if="value.length">
      <Draggable
        item-key="id"
        class="flex flex-col gap-2"
        v-bind="{ animation: 200 }"
        :disabled="disabled"
        @start="dragging = true"
        @end="dragging = false"
        @update="handleUpdateSort"
      >
        <CategoryTopicTag
          v-for="topic in draggableValue"
          :key="topic.id"
          :topic="topic"
          :category="topic.category"
          :topic-label="topic.label"
          :sentiment="disabledSentiment || !('sentiment' in topic) ? undefined : topic.sentiment"
          :category-color="topic.color_from_palette"
          :show-sentiment="optionalSentiment ? !!topic.sentiment : !disabledSentiment"
          :sentiment-editable="!disabled"
          :class="disabled ? 'hover:cursor-default' : 'hover:cursor-row-resize'"
          :readonly="Boolean(disabled)"
          :highlighted="isHighlighted(topic)"
          @click:delete="() => handleRemoveTopic(topic.id)"
          @update:sentiment="handleUpdateSentiment($event, topic.id)"
        />
      </Draggable>
    </div>

    <div v-else>
      <label class="text-gray-800">
        {{ $t('projects.topics_view.no_applied_topics') }}
      </label>
    </div>

    <div
      v-on-click-outside="closeDropdown"
      @click="!disabled && toggleDropdown()"
      @mousemove="onMouseMove"
      @keydown="preventMouseOver = true"
      @keydown.left.stop="onLeft"
      @keydown.right.stop="onRight"
      @keydown.esc.prevent.stop="onEscape"
      @keydown.up.prevent.stop="onUp"
      @keydown.down.prevent.stop="onDown"
      @keydown.enter.prevent="onEnter"
      @keydown.delete="onDelete"
    >
      <div v-if="!hideLabels" class="mt-4">
        <label class="font-medium">
          {{ $t('projects.topics_view.add_topics') }}
        </label>
      </div>

      <NInput
        ref="inputRef"
        v-model:value="search"
        class="mt-2"
        :placeholder="$t('projects.topics_view.add_topics_input')"
        size="large"
        :disabled="!!disabled"
      >
        <template #suffix>
          <FaIcon :icon="!selectOpen ? 'fa-chevron-down' : 'fa-chevron-up'" />
        </template>
      </NInput>

      <NAlert v-if="disabled && disabledMessage" type="warning" class="mt-2" title="">
        {{ disabledMessage }}
      </NAlert>

      <NCard
        v-if="selectOpen"
        ref="listRef"
        :style="`max-height: ${maxDropDownHeight}`"
        content-style="padding: 0; overflow-y: visible; overflow-x: hidden;"
        footer-style="padding: 0;"
        :class="{ absolute }"
        class="z-10 mt-2 w-full overflow-y-auto overflow-x-hidden"
        @click.stop.prevent
      >
        <div
          v-for="(cat, catName) in filteredOptions"
          :key="catName"
          :ref="(el) => (optionRefs[`category-${catName}`] = el as HTMLElement)"
          @click="toggleCategory(catName)"
        >
          <div
            class="p-3 hover:cursor-pointer"
            :class="{
              'b-b-1 b-divider b-solid mb-2': expandedCategories.includes(catName),
              'bg-c-base': isHighlighted(catName),
            }"
            @mouseover="onMouseOverOption(catName)"
          >
            <div class="flex w-full items-center justify-between">
              <div class="flex items-center gap-1 text-xs font-semibold">
                <div class="w-4px h-20px rounded-xl" :style="{ backgroundColor: cat.color }" />
                {{ catName }}
              </div>
              <FaIcon :icon="!expandedCategories.includes(catName) ? 'fa-chevron-down' : 'fa-chevron-up'" size="sm" />
            </div>
          </div>

          <div v-if="expandedCategories.includes(catName)">
            <div
              v-for="(topic, index) in cat.topics"
              :key="index"
              :ref="(el) => (optionRefs[`topic-${topic.id}`] = el as HTMLElement)"
            >
              <UseMouseInElement v-slot="{ isOutside }">
                <div
                  class="flex h-[32px] w-full items-center pl-3 pr-4 hover:cursor-pointer"
                  :class="{
                    'bg-c-base': isHighlighted(topic),
                  }"
                  @mouseover="onMouseOverOption(topic)"
                  @click.prevent.stop="handleAddTopic(topic, null)"
                >
                  <div class="text-xs">
                    {{ topic.label }}
                  </div>
                  <div
                    v-if="(!isOutside || isHighlighted(topic)) && topic.sentiment_enabled && !disabledSentiment"
                    class="ml-2 flex items-center"
                  >
                    <NTooltip
                      v-for="sentiment in CodeSentiments"
                      :key="sentiment"
                      :show="
                        selectedSentimentPointer !== null && SENTIMENT_KEY_MAP[selectedSentimentPointer] === sentiment
                      "
                      placement="top"
                      class="!px-2 !py-1"
                    >
                      <template #trigger>
                        <NButton
                          icon
                          quaternary
                          rounded
                          size="tiny"
                          class="hover:bg-white! dark:hover:bg-gray-800! transition"
                          :class="{
                            'bg-white! dark:bg-gray-800!':
                              selectedSentimentPointer !== null &&
                              SENTIMENT_KEY_MAP[selectedSentimentPointer] === sentiment,
                          }"
                          @click.stop.prevent="handleAddTopic(topic, sentiment)"
                        >
                          <FaIcon
                            :icon="sentimentMap.getSentimentInformation(sentiment).icon"
                            :color="sentimentMap.getSentimentInformation(sentiment).color"
                            size="lg"
                          />
                        </NButton>
                      </template>
                      <span class="text-xs">{{ $t('common.add_as') }} {{ sentiment }}</span>
                    </NTooltip>
                  </div>
                </div>
              </UseMouseInElement>
            </div>
          </div>

          <NDivider class="!my-0" :class="expandedCategories.includes(catName) && '!mt-2'" />
        </div>

        <div v-if="allowNewTopic" class="align-center my-3 flex justify-center">
          <NButton
            ref="newButtonRef"
            size="small"
            @click="
              () => {
                $emit('click:new-topic', search)
                nextTick(() => closeDropdown())
              }
            "
            @mouseover="onMouseOverOption('new')"
          >
            {{ $t('projects.topics_view.new_topic') }} {{ search.length ? `'${search}'` : '' }}
          </NButton>
        </div>

        <div v-if="!allowNewTopic && !Object.keys(filteredOptions).length" class="m-3 text-sm text-neutral-500">
          {{ $t('projects.topics_view.no_matching_topics') }}
        </div>
      </NCard>
    </div>
    <div v-if="additionalFilters" class="mt-3 flex flex-col">
      <!-- TODO: Translate  -->
      <NCheckbox
        :checked="typeof noTopics === 'string'"
        class="mb-1"
        :disabled="!!withTopics"
        @update:checked="
          (checked: boolean) => {
            emit('update:no-topics', checked)
            emit('update:value', [])
          }
        "
      >
        Show responses without topics
      </NCheckbox>
      <!-- TODO: Translate  -->
      <NCheckbox
        :checked="typeof withTopics === 'string'"
        :disabled="!!noTopics"
        @update:checked="(checked: boolean) => emit('update:with-topics', checked)"
      >
        Only show responses with topics
      </NCheckbox>
    </div>
  </div>
</template>

<script setup lang="ts">
import CategoryTopicTag from '@/components/CategoryTopicTag.vue'
import { CodeSentiments, type RowTopic, type TopicUIResponse } from '@/api'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'
import { type InputInst, type NButton, type NCard, NCheckbox, useMessage } from 'naive-ui'
import { UseMouseInElement, vOnClickOutside } from '@vueuse/components'
import { clone, findIndex, findKey, isEqual } from 'lodash-es'
import { computed, nextTick, onBeforeUnmount, onBeforeUpdate, onMounted, onUpdated, ref, watch } from 'vue'
import { isElementVisible } from '@/utils/element'
import { useEventBus } from '@/composables/useEventBus'
import { useSentimentMap } from '@insight-elements/helpers'

interface ITopicSelectProps {
  value: RowTopic[]
  options: Record<string, TCategory>
  disabled?: boolean | string
  allowNewTopic?: boolean
  modalOpen?: boolean
  disabledMessage?: string
  disabledSentiment?: boolean
  optionalSentiment?: boolean
  hideLabels?: boolean
  noTopics?: string
  withTopics?: string
  absolute?: boolean
  additionalFilters?: boolean
}

type TKeyboardStateOptionType = 'chip' | 'category' | 'topic' | 'new'

interface IKeyboardStateOption {
  type: TKeyboardStateOptionType
  option: string | RowTopic | TopicUIResponse
}

const props = withDefaults(defineProps<ITopicSelectProps>(), { absolute: true })

const emit = defineEmits<{
  'update:value': [value: RowTopic[]]
  'click:new-topic': [value: string]
  'trigger:close-drawer': [value: boolean]
  'update:no-topics': [value: boolean]
  'update:with-topics': [value: boolean]
}>()
const sentimentMap = useSentimentMap()

const SCROLL_UP = 1
const SCROLL_DOWN = -1

const SENTIMENT_KEY_MAP = {
  0: CodeSentiments.NEUTRAL,
  1: CodeSentiments.POSITIVE,
  2: CodeSentiments.NEGATIVE,
}

const message = useMessage()
const eventBus = useEventBus()

const draggableValue = ref<RowTopic[]>(props.value)
const dragging = ref(false)
const inputRef = ref<InputInst | null>(null)
const newButtonRef = ref<typeof NButton | null>(null)
const listRef = ref<typeof NCard | null>(null)
const optionRefs = ref<Record<string, HTMLElement>>({})
const inputOffset = ref<number>(0)
const selectOpen = ref(false)
const search = ref('')
const preventMouseOver = ref(true)
const selectedOptionPointer = ref<null | number>(null)
const selectedSentimentPointer = ref<null | keyof typeof SENTIMENT_KEY_MAP>(null)
const expandedCategories = ref<string[]>([])

const filteredOptions = computed(() => {
  if (!search.value) return props.options

  const searchedOptions: Record<string, TCategory> = {}

  Object.keys(props.options).forEach((key) => {
    const searchedTopics = props.options[key].topics.filter(
      (topic) =>
        // topic label match
        topic.label.toLowerCase().includes(search.value.toLowerCase()) ||
        // category label match
        props.options[key].label.toLowerCase().includes(search.value.toLowerCase())
    )

    if (searchedTopics.length) {
      searchedOptions[key] = {
        ...props.options[key],
        topics: searchedTopics,
      }
    }
  })

  return searchedOptions
})

const keyboardOptionsStateMap = computed<IKeyboardStateOption[]>(() => {
  const state: IKeyboardStateOption[] = []

  props.value.forEach((assignedTopic: RowTopic) => {
    state.push({ type: 'chip', option: assignedTopic })
  })

  Object.keys(filteredOptions.value).forEach((cat: string) => {
    state.push({ type: 'category', option: cat })

    if (expandedCategories.value.includes(cat)) {
      filteredOptions.value[cat].topics.forEach((topic: TopicUIResponse) => {
        state.push({ type: 'topic', option: topic })
      })
    }
  })

  state.push({ type: 'new', option: 'new' })

  return state
})

const selectedOption = computed(() =>
  selectedOptionPointer.value !== null ? keyboardOptionsStateMap.value[selectedOptionPointer.value] : null
)

const maxDropDownHeight = computed(() => `${Math.round(window.innerHeight - inputOffset.value - 70)}px`)

const handleRemoveTopic = (topicId: string) => {
  emit(
    'update:value',
    props.value.filter((topic) => topic.id !== topicId)
  )

  selectedOptionPointer.value = null
}

const handleAddTopic = (topic: TopicUIResponse | RowTopic, sentiment: null | SentimentOption) => {
  if (!props.disabledSentiment && !props.optionalSentiment && !sentiment && topic.sentiment_enabled) {
    message.info('Please select a sentiment')
    return
  }

  emit('update:value', [...props.value, { ...topic, sentiment }])
}

const handleUpdateSentiment = (sentiment: SentimentOption, topicId: string) => {
  emit(
    'update:value',
    props.value.map((topic) => (topic.id === topicId ? { ...topic, sentiment } : topic))
  )
}

const handleUpdateSort = () => {
  emit('update:value', draggableValue.value)
}

const toggleDropdown = () => (!selectOpen.value ? openDropdown() : closeDropdown())

const openDropdown = () => {
  selectOpen.value = true
  inputRef.value?.focus()
  eventBus.emit('set-visible-topic-select', true)
}

const closeDropdown = () => {
  selectOpen.value = false
  resetSelect()
  eventBus.emit('set-visible-topic-select', false)

  nextTick(() => inputRef.value?.blur())
}

const isHighlighted = (option: string | TopicUIResponse | RowTopic) => {
  if (selectedOptionPointer.value === null || !selectedOption.value) return false
  if (typeof selectedOption.value.option === 'string') return selectedOption.value.option === option
  if (
    (selectedOption.value.type === 'topic' || selectedOption.value.type === 'chip') &&
    typeof option !== 'string' &&
    option.id
  ) {
    return selectedOption.value.option.id === option.id
  }
  return false
}

const onMouseMove = () => {
  if (preventMouseOver.value) preventMouseOver.value = false
}

const onMouseOverOption = (option: string | TopicUIResponse | RowTopic) => {
  if (preventMouseOver.value) return

  inputRef.value?.focus()
  selectedOptionPointer.value = findIndex(keyboardOptionsStateMap.value, (options) => isEqual(options.option, option))
}

const toggleCategory = (cat: string) =>
  expandedCategories.value.includes(cat)
    ? (expandedCategories.value = expandedCategories.value.filter((c) => c !== cat))
    : (expandedCategories.value = [...expandedCategories.value, cat])

const scrollListIfNeeded = (direction: 1 | -1) => {
  if (!selectedOption.value || !selectOpen.value) return

  let selectedEl: HTMLElement | undefined = undefined
  const { option, type } = selectedOption.value

  if (type === 'category') selectedEl = optionRefs.value[`category-${option}`]
  if (type === 'topic' && typeof option !== 'string' && option.id) selectedEl = optionRefs.value[`topic-${option.id}`]

  if (listRef.value && selectedEl && !isElementVisible(selectedEl, listRef.value?.$el)) {
    selectedEl.scrollIntoView({
      block: direction === SCROLL_DOWN ? 'end' : 'start',
    })
  }
}

const resetSelect = () => {
  search.value = ''
  expandedCategories.value = []
  selectedOptionPointer.value = null
  selectedSentimentPointer.value = null
}

const onKeypress = (e: KeyboardEvent) => {
  if (props.modalOpen || props.disabled) return

  preventMouseOver.value = true

  // Dropdown is closed and user typed something, or pressed enter
  if ((/^[a-zA-Z0-9-_ ]$/.test(e.key) || e.key === 'Enter') && !e.metaKey && !e.ctrlKey) {
    openDropdown()
  }

  if (e.key === 'Escape' && !selectOpen.value) {
    emit('trigger:close-drawer', true)
  }
}

const onEscape = () => {
  if (search.value) {
    search.value = ''
    inputRef.value?.focus()
  }
  if (selectOpen.value) closeDropdown()
}

const onUp = (e: KeyboardEvent) => {
  // chip re-ordering
  if (e.shiftKey && selectedOption.value?.type === 'chip' && selectedOptionPointer.value) {
    const resorted = clone(props.value)

    resorted.splice(selectedOptionPointer.value - 1, 0, resorted.splice(selectedOptionPointer.value, 1)[0])

    emit('update:value', resorted)

    selectedOptionPointer.value = selectedOptionPointer.value - 1
    selectedSentimentPointer.value = null
    return
  }

  if (selectedOptionPointer.value === null) selectedOptionPointer.value = props.value.length
  if (selectedOptionPointer.value > 0) selectedOptionPointer.value -= 1

  selectedSentimentPointer.value = null
  scrollListIfNeeded(SCROLL_UP)
}

const onDown = (e: KeyboardEvent) => {
  // chip re-ordering
  if (
    e.shiftKey &&
    selectedOption.value?.type === 'chip' &&
    selectedOptionPointer.value !== null &&
    selectedOptionPointer.value < props.value.length - 1
  ) {
    const resorted = clone(props.value)

    resorted.splice(selectedOptionPointer.value + 1, 0, resorted.splice(selectedOptionPointer.value, 1)[0])

    emit('update:value', resorted)

    selectedOptionPointer.value = selectedOptionPointer.value + 1
    selectedSentimentPointer.value = null
    return
  }

  if (selectedOptionPointer.value === null) selectedOptionPointer.value = props.value.length - 1
  if (selectedOptionPointer.value < keyboardOptionsStateMap.value.length - 1) selectedOptionPointer.value += 1

  selectedSentimentPointer.value = null
  scrollListIfNeeded(SCROLL_DOWN)
}

const onLeft = () => {
  if (!selectedOption.value) return

  const { type, option } = selectedOption.value

  if (type === 'category' && typeof option === 'string') toggleCategory(option)
  if (type === 'topic' && typeof option !== 'string' && option.sentiment_enabled) {
    selectedSentimentPointer.value !== null && selectedSentimentPointer.value > 0
      ? (selectedSentimentPointer.value -= 1)
      : (selectedSentimentPointer.value = 2)
  }
  if (type === 'chip' && typeof option !== 'string' && 'sentiment' in option && option.sentiment_enabled) {
    const selectedSentimentKey = Number(findKey(SENTIMENT_KEY_MAP, (o) => o === option.sentiment))

    selectedSentimentKey - 1 !== -1
      ? handleUpdateSentiment(
          SENTIMENT_KEY_MAP[(selectedSentimentKey - 1) as keyof typeof SENTIMENT_KEY_MAP],
          option.id
        )
      : handleUpdateSentiment(SENTIMENT_KEY_MAP[2], option.id)
  }
}

const onRight = () => {
  if (!selectedOption.value) return

  const { type, option } = selectedOption.value

  if (type === 'category' && typeof option === 'string') toggleCategory(option)
  if (type === 'topic' && typeof option !== 'string' && option.sentiment_enabled) {
    selectedSentimentPointer.value !== null && selectedSentimentPointer.value < 2
      ? (selectedSentimentPointer.value += 1)
      : (selectedSentimentPointer.value = 0)
  }
  if (type === 'chip' && typeof option !== 'string' && 'sentiment' in option && option.sentiment_enabled) {
    const selectedSentimentKey = Number(findKey(SENTIMENT_KEY_MAP, (o) => o === option.sentiment))

    handleUpdateSentiment(
      selectedSentimentKey + 1 !== 3
        ? SENTIMENT_KEY_MAP[(selectedSentimentKey + 1) as keyof typeof SENTIMENT_KEY_MAP]
        : handleUpdateSentiment(SENTIMENT_KEY_MAP[0], option.id),
      option.id
    )
  }
}

const onEnter = (e: KeyboardEvent) => {
  if (!selectOpen.value) {
    openDropdown()
  }
  if (!selectedOption.value || e.metaKey) return

  const { type, option } = selectedOption.value

  if (type === 'new') emit('click:new-topic', search.value)
  if (type === 'category' && typeof option === 'string') toggleCategory(option)
  if (type === 'topic' && typeof option !== 'string') {
    const sentiment =
      option.sentiment_enabled && selectedSentimentPointer.value !== null
        ? SENTIMENT_KEY_MAP[selectedSentimentPointer.value]
        : null

    handleAddTopic(option, sentiment)

    return
  }
}

const onDelete = () => {
  if (!selectedOption.value) return

  const { type, option } = selectedOption.value

  if (type === 'chip' && typeof option !== 'string' && option.id) {
    handleRemoveTopic(option.id)
  }
}

watch(
  () => props.value,
  (newVal) => {
    draggableValue.value = newVal
  }
)

watch(
  () => selectedOptionPointer.value,
  (newVal) => {
    if (newVal !== keyboardOptionsStateMap.value.length - 1) {
      newButtonRef.value?.selfElRef?.blur()
      inputRef.value?.focus()
    } else if (newVal === keyboardOptionsStateMap.value.length - 1) {
      newButtonRef.value?.selfElRef?.focus()
    }
  }
)

watch(
  () => search.value,
  (newVal) => {
    if (newVal) {
      expandedCategories.value = Object.keys(filteredOptions.value)

      const foundTopicIndex = findIndex(keyboardOptionsStateMap.value, (option) => option.type === 'topic')
      const newTopicIndex = props.value.length

      selectedOptionPointer.value = foundTopicIndex !== -1 ? foundTopicIndex : newTopicIndex
      return
    }

    selectedOptionPointer.value = null
    selectedSentimentPointer.value = null
    expandedCategories.value = []
  }
)

onBeforeUpdate(() => {
  optionRefs.value = {}
})

onUpdated(() => {
  if (inputRef.value) {
    inputOffset.value = inputRef.value?.inputElRef?.getBoundingClientRect().top || 0
  }
})

onMounted(() => {
  window.addEventListener('keydown', onKeypress)
})

onBeforeUnmount(() => {
  window.removeEventListener('keydown', onKeypress)
})
</script>

<style scoped></style>

<template>
  <TolgeeProvider>
    <NaiveProvider>
      <!-- has a layout -->
      <Component :is="layout" v-if="layout">
        <RouterView />
      </Component>
      <!-- no layout -->
      <RouterView v-else />
      <!-- This is important to have for Posthog to render the survey -->
      <PosthogSurveyContainer />
    </NaiveProvider>
  </TolgeeProvider>
</template>

<script setup lang="ts">
/* eslint-disable object-shorthand */

import DefaultLayout from '@/views/_layouts/default.layout.vue'
import NaiveProvider from '@/components/common/NaiveProvider.vue'
import PosthogSurveyContainer from '@/components/PosthogSurveyContainer.vue'
import SharedLayout from '@/views/_layouts/shared.layout.vue'
import { type Component, computed, onBeforeMount } from 'vue'
import { TolgeeProvider } from '@tolgee/vue'
import { useRoute } from 'vue-router'
import { useThemeStore } from './store'

defineOptions({
  name: 'CaplenaApp',
})

const themeStore = useThemeStore()
// keys here must match with the meta.layout in router file
// values must match to component names
const layouts: Record<TAppLayout, Component> = {
  DefaultLayout: DefaultLayout,
  SharedLayout: SharedLayout,
}

onBeforeMount(async () => {
  // get theme from localStorage or set light by default
  const theme = (localStorage.getItem('theme') as TCaplenaTheme) || 'light'

  themeStore.setThemeWithName(theme)
})

const route = useRoute()
const layout = computed(() => (route.meta.layout ? layouts[route.meta.layout] : undefined))
</script>

<template>
  <div class="mx-auto mt-auto w-full">
    <NBadge :value="unreadCount" :max="99" class="w-full">
      <SidebarMenuItem
        class="w-full"
        :item="{
          name: $t('navigation.message_us'),
          icon: 'far fa-comment-dots',
          buttonClass: 'active intercom-button w-full',
        }"
        :collapsed="Boolean(collapsed)"
        :disabled="Boolean(disabled)"
      />
    </NBadge>
  </div>
</template>

<script setup lang="ts">
import SidebarMenuItem from '@/components/common/SidebarMenuItem.vue'
import { intercom } from '@/plugins/intercom'
import { onMounted, ref } from 'vue'

defineProps<{
  collapsed?: boolean
  disabled?: boolean
}>()

const unreadCount = ref(0)

onMounted(() => {
  intercom.onUnreadCountChange((count) => {
    unreadCount.value = count
  })
})
</script>

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarkdownText } from './MarkdownText';
export type MarkdownTextStaticContentResponse = {
  id: string;
  kind?: MarkdownTextStaticContentResponse.kind;
  static_content?: MarkdownText;
};
export namespace MarkdownTextStaticContentResponse {
  export enum kind {
    MARKDOWN_TEXT = 'MarkdownText',
  }
}


import { AvailableWidgetId, ColumnKind, SelectedRowsInsightElementResponse } from '@/api'

export const selectedRowsMock: SelectedRowsInsightElementResponse = {
  id: 'ie_1',
  kind: 'SelectedRowsInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  visible_center: SelectedRowsInsightElementResponse.visible_center.PASSIVES_ROWS,
  visible_left: SelectedRowsInsightElementResponse.visible_left.PROMOTERS_ROWS,
  visible_right: SelectedRowsInsightElementResponse.visible_right.DETRACTORS_ROWS,
  detractors_rows: {
    id: 'wg_1',
    kind: 'TtaColumnRows',
    name: 'Detractors Rows',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.DETRACTORS_ROWS,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  detractors_summary: {
    id: 'wg_2',
    kind: 'Text',
    name: 'Detractors Summary',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.DETRACTORS_SUMMARY,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  passives_rows: {
    id: 'wg_3',
    kind: 'TtaColumnRows',
    name: 'Passives Rows',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.PASSIVES_ROWS,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  passives_summary: {
    id: 'wg_4',
    kind: 'Text',
    name: 'Passives Summary',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.PASSIVES_SUMMARY,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  promoters_rows: {
    id: 'wg_5',
    kind: 'TtaColumnRows',
    name: 'Promoters Rows',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.PROMOTERS_ROWS,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  promoters_summary: {
    id: 'wg_6',
    kind: 'Text',
    name: 'Promoters Summary',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.PROMOTERS_SUMMARY,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  filters: [],
  available_filters: {},
}

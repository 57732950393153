import { API } from '@/api'
import { BackendFetch, Tolgee } from '@tolgee/vue'
import { FormatIcu } from '@tolgee/format-icu'
import { dateDeDE, dateEnGB, deDE } from 'naive-ui'
import { formatDistanceToNow } from 'date-fns'
import { merge } from 'lodash-es'
import { nextTick, ref } from 'vue'
import { setLocale } from 'yup'
import { useAuthStore } from '@/store'

export const supportedLanguages: Array<TLanguage> = [
  {
    label: 'English',
    value: 'en',
  },
  {
    label: 'Deutsch',
    value: 'de',
  },
]

const shouldUseLiveTranslations = import.meta.env.COMMAND === 'build' && import.meta.env.MODE !== 'production'

export const tolgee = Tolgee()
  // .use(import.meta.env.MODE !== 'production' ? DevTools() : undefined)
  .use(shouldUseLiveTranslations ? BackendFetch() : undefined)
  .use(FormatIcu())
  .init({
    availableLanguages: supportedLanguages.map((l) => l.value),
    language: 'en',
    // fallback works only on stage or production
    fallbackLanguage: import.meta.env.MODE !== 'development' ? 'en' : undefined,

    // NOTE: these should be NEVER provided on production env
    apiUrl: shouldUseLiveTranslations ? import.meta.env.CAPP_TOLGEE_API_URL : undefined,
    apiKey: shouldUseLiveTranslations ? import.meta.env.CAPP_TOLGEE_API_KEY : undefined,

    // for production
    staticData: {
      en: () => import('@/assets/locales/en.json'),
      de: () => import('@/assets/locales/de.json'),
    },
  })

// include additional tools except production
if (import.meta.env.MODE !== 'production') {
  // eslint-disable-next-line node/file-extension-in-import
  import('@tolgee/web/tools').then((module) => {
    tolgee.addPlugin(module.InContextTools())
  })
}

export const naiveLocale = ref()

// load Naive UI translations & date time locales depending on i18n
const setNaiveLocale = (locale: TLanguage['value'] = 'en') => {
  if (locale === 'en') naiveLocale.value = undefined
  else if (locale === 'de') naiveLocale.value = { locale: deDE, dateLocale: dateDeDE }
}

export async function setLanguage(locale: TLanguage['value'] = 'en', initialLoad = false) {
  if (!supportedLanguages.some((lang) => lang.value === locale)) {
    console.error(`[i18n]: Unsupported language: ${locale}`)
    return undefined
  }

  // return if the incoming language value is the same
  if (tolgee.getLanguage() === locale) return undefined

  const authStore = useAuthStore()

  await tolgee.changeLanguage(locale)

  // set naive default language
  await setNaiveLocale(locale)

  // set to localStorage
  localStorage.setItem('language', locale)

  // update user profile if it is not initial app load
  if (!initialLoad) await authStore.updateUser({ language: locale })

  // set axios headers
  API.defaults.headers.common['Accept-Language'] = locale

  // set to html
  document.querySelector('html')?.setAttribute('lang', locale)

  setLocale({
    mixed: {
      required: tolgee.t('formValidation.required'),
    },
    number: {
      min: ({ min }) => tolgee.t('formValidation.min', { min }),
      max: ({ max }) => tolgee.t('formValidation.max', { max }),
      integer: () => tolgee.t('formValidation.numeric'),
    },
    string: {
      email: tolgee.t('formValidation.email'),
    },
  })

  return nextTick()
}

export function formatDate(date: Date | number | string, showYear = true) {
  if (!date) throw Error('date parameter cant be empty!')

  const dateToFormat = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = tolgee.getLanguage()
  const options: Intl.DateTimeFormatOptions = showYear
    ? { year: 'numeric', month: 'short', day: 'numeric' }
    : { month: 'short', day: '2-digit' }

  // TO DO: Here we need to provide proper locale to make this work correctly
  // for now setting US locale as default (to get comma between month and year)
  return dateToFormat.toLocaleDateString(`${locale === 'en' ? 'en-US' : locale}`, options)
}

export function formatTime(date: Date | number | string, showSeconds = false) {
  if (!date) throw Error('date parameter cant be empty!')

  const dateToFormat = typeof date === 'number' || typeof date === 'string' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = tolgee.getLanguage()
  // TO DO: AM/PM difference depending on user locale
  const options: Intl.DateTimeFormatOptions = showSeconds
    ? { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false }
    : { hour: '2-digit', minute: '2-digit', hour12: false }

  return dateToFormat.toLocaleTimeString(locale, options)
}

export function formatDateAsSentence(date: Date | number | string) {
  const dateToFormat = typeof date === 'string' || typeof date === 'number' ? new Date(date) : date

  // check if parsed date is not valid
  if (isNaN(Number(dateToFormat))) return null

  const locale = tolgee.getLanguage() as TLanguage['value']

  const locales = {
    en: dateEnGB,
    de: dateDeDE,
  }

  // NaiveUI also uses date-fns under the hood for date formatting, so we can use same locales
  return formatDistanceToNow(dateToFormat, {
    locale: locales[locale].locale,
    addSuffix: true,
  })
}

export function formatNumber(value: number, options: Intl.NumberFormatOptions = {}) {
  const locale = tolgee.getLanguage()

  const defaultOpts: Intl.NumberFormatOptions = {
    maximumFractionDigits: 1,
    useGrouping: false,
  }

  return new Intl.NumberFormat(locale, merge(defaultOpts, options)).format(value)
}

<!-- TODO: Translate  -->
<template>
  <div class="login-view-container">
    <NGrid cols="16" item-responsive>
      <NGridItem span="14 400:16 600:8 800:8 1000:6 1600:4" offset="1 400:0 600:4 800:4 1000:5 1600:6">
        <NCard>
          <NSpace justify="center">
            <NImage :width="100" :src="logoURL" preview-disabled />
          </NSpace>
          <NForm>
            <!-- Email -->
            <LoginStep
              v-if="!currentStep"
              v-model="email"
              form-path="email"
              placeholder="Email"
              :step="currentStep"
              :loading="loading"
              :form-item-props="emailAttrs.fieldAttrs"
              :reset="passwordReset"
              v-on="emailAttrs.inputListeners"
              @on:next="passwordReset ? reset() : login(false, 'email')"
              @on:reset="passwordReset = !passwordReset"
            />
            <!-- Password -->
            <LoginStep
              v-if="currentStep === LoginResponse.next_step.PASSWORD"
              v-model="password"
              type="password"
              form-path="password"
              placeholder="Password"
              :step="currentStep"
              :loading="loading"
              :form-item-props="passwordAttrs.fieldAttrs"
              v-on="passwordAttrs.inputListeners"
              @on:next="login(false, 'password')"
              @on:reset="reset"
            />
            <!-- Auth Code -->
            <LoginStep
              v-if="
                currentStep === LoginResponse.next_step._2FA_DEVICE ||
                currentStep === LoginResponse.next_step._2FA_TOKEN
              "
              v-model="authCode"
              form-path="authCode"
              :placeholder="authCodePlaceholder"
              :step="currentStep"
              :loading="loading"
              :input-props="{
                name: 'one-time-code',
                inputmode: 'numeric',
              }"
              :form-item-props="authCodeAttrs.fieldAttrs"
              v-on="authCodeAttrs.inputListeners"
              @on:next="login(false, 'authCode')"
            >
              <!-- 2FA device select -->
              <template v-if="availableDevices && availableDevices.length && availableDevices.length > 1">
                <NButton v-if="!deviceSelectMode" type="primary" text class="mt-1" @click="deviceSelectMode = true">
                  {{ $t('settings.security.show_devices') }}
                </NButton>
                <div v-else class="mt-1 flex gap-2">
                  <NButton
                    v-for="device in availableDevices"
                    :key="device.id"
                    type="primary"
                    secondary
                    class="mt-2 flex"
                    :disabled="loading"
                    size="small"
                    @click="() => select2FADevice(device)"
                  >
                    {{ device.type }}
                  </NButton>
                </div>
              </template>
            </LoginStep>
          </NForm>
        </NCard>
      </NGridItem>
    </NGrid>
  </div>
</template>

<script setup lang="ts">
import LoginStep from './components/LoginStep.vue'
import logoURL from '@/assets/img/logo.svg'
import { ApiError, DeviceResponse, LoginResponse, getErrorMsg } from '@/api'
import { authCodeRule, passwordRule, useValidatedForm } from '@/plugins/form-validation'
import { computed, nextTick, onMounted, ref } from 'vue'
import { useTranslate } from '@tolgee/vue'

import { object, string } from 'yup'
import { useAuthStore } from '@/store'
import { useMessage } from 'naive-ui'
import { useRouter } from 'vue-router'
import { useUrlSearchParams } from '@vueuse/core'

const formSchema = object({
  email: string().email().required(),
  password: passwordRule(),
  authCode: authCodeRule(),
})

const {
  isFormValid,
  isFieldValid,
  formFields: {
    email: [email, emailAttrs],
    password: [password, passwordAttrs],
    authCode: [authCode, authCodeAttrs],
  },
} = useValidatedForm(formSchema, {
  authCode: '',
  email: '',
  password: '',
})

const auth = useAuthStore()
const router = useRouter()
const { t } = useTranslate()
const message = useMessage()
const deviceSelectMode = ref(false)
const loading = ref(false)
const currentStep = ref()
const selectedDevice = ref<DeviceResponse>()
const availableDevices = ref<DeviceResponse[]>([])
const redirectPath = useUrlSearchParams('history').go_to as string | undefined
const passwordReset = ref(false)

const authCodePlaceholder = computed(() => {
  return t.value(`settings.security.code_select`, {
    device: t.value(`settings.security.device_types.${selectedDevice.value?.type}`),
  })
})

const throwError = (msg: string | string[] = 'Something went wrong during login') => {
  if (Array.isArray(msg)) {
    msg.forEach((m) => {
      message.error(m)
      console.error(m)
    })
  } else if (typeof msg === 'string') {
    message.error(msg)
    console.error(msg)
  }
}

const redirect = (path: string | undefined = undefined) => {
  // custom url param
  if (path) router.push(path)

  // no custom url && has redirectURL
  if (redirectPath) {
    router.push(redirectPath)
    return
  }

  // default: redirect home
  router.push('/')
}

const reset = async () => {
  if (!isFieldValid('email')) return

  loading.value = true

  const res = await auth.reset({ email: email.value })

  loading.value = false

  if (res?.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  message.success('A message containing the reset link was sent to your email address')
  passwordReset.value = false
}

const login = async (skipFormValidation = false, field?: 'authCode' | 'email' | 'password') => {
  if (!skipFormValidation) {
    if (field && !isFieldValid(field)) return
    else if (!field && !isFormValid.value) return
  }

  loading.value = true

  const res = await auth.login({
    email: email.value,
    password: password.value,
    token: authCode.value,
    device_id: selectedDevice.value?.id,
  })

  loading.value = false

  if (!res || res.error) {
    throwError(getErrorMsg(res?.error as ApiError))
    return
  }

  // login successful - no 2FA configured
  if (res.nextStep === LoginResponse.next_step.DONE) {
    await auth.getUser()
    redirect()
    return
  }

  if (res.nextStep === LoginResponse.next_step.PASSWORD) {
    // nextTick(() => passwordRef.value?.focus())
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_DEVICE && res.devices && res.devices.length) {
    // Select default device
    availableDevices.value = res.devices

    const preferredTypes = ['app', 'sms', 'backup_codes']
    // Find the first matching device from the preferred types
    const defaultDevice = preferredTypes.reduce<DeviceResponse | undefined>(
      (result, type) => result || res.devices?.find((device) => device.type === type),
      undefined
    )

    if (defaultDevice) select2FADevice(defaultDevice)
  }

  // configure UI for 2FA challenge
  if (res.nextStep === LoginResponse.next_step._2FA_TOKEN) {
    selectedDevice.value = res.device
  }

  currentStep.value = res.nextStep
}

const select2FADevice = (device: DeviceResponse) => {
  selectedDevice.value = device
  authCode.value = ''
  nextTick(() => login(true))
  deviceSelectMode.value = false
}

onMounted(() => {
  if (auth.isLoggedIn) redirect()

  // emailRef.value?.focus()
})
</script>

<style lang="scss" scoped>
.login-view-container {
  padding-top: 25vh;
}
</style>

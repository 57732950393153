import de_DE from '@vavt/cm-extension/dist/locale/de-DE.js'
import { config } from 'md-editor-v3'
import 'md-editor-v3/lib/style.css'

export function useMDEditorV3() {
  config({
    editorExtensions: {
      iconfont: '/static/md-editor-iconfont.js',
      // disabled
      prettier: {
        standaloneJs: undefined,
        parserMarkdownJs: undefined,
      },
      screenfull: {
        js: undefined,
      },
      // serve from assets
      cropper: {
        js: '/static/cropper/cropper.min.js',
        css: '/static/cropper/cropper.min.css',
      },
      highlight: {
        js: '/static/highlight/highlight.min.js',
        css: {
          atom: {
            light: '/static/highlight/atom-one-dark.min.css',
            dark: '/static/highlight/atom-one-dark.min.css',
          },
        },
      },
    },
    editorConfig: {
      languageUserDefined: {
        'de-DE': de_DE,
      },
    },
  })
}

export function isElementVisible(el: HTMLElement, holder: HTMLElement): boolean | undefined {
  if (!el || !holder) {
    console.warn('isElementVisible: el or holder is undefined')
    return undefined
  }

  const { top, bottom, height } = el.getBoundingClientRect()
  const holderRect = holder.getBoundingClientRect()

  return top <= holderRect.top ? holderRect.top - top + height <= height : bottom - holderRect.bottom + height <= height
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DateAggregations } from './DateAggregations';
import type { WidgetDataWarning } from './WidgetDataWarning';
export type SegmentDateAggregationCounts = {
  kind?: SegmentDateAggregationCounts.kind;
  warnings?: Array<WidgetDataWarning>;
  description?: string;
  counts?: Array<number>;
  date_labels?: Array<string>;
  date_aggregation?: DateAggregations;
  segment_id?: string;
};
export namespace SegmentDateAggregationCounts {
  export enum kind {
    SEGMENT_COUNT_ROWS_BY_CREATED = 'segment-count-rows-by-created',
  }
}


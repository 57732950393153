<template>
  <NSpin :show="loading">
    <!-- Input -->
    <slot name="input" :placeholder="{ inputPlaceholder, searchKey }">
      <template v-if="searchable && items.length">
        <div :class="searchClass ? searchClass : 'px-3 py-2'">
          <NInput
            v-model:value="searchKey"
            :placeholder="inputPlaceholder ? inputPlaceholder : $t('common.search')"
            clearable
            @keydown.esc="searchKey = ''"
          >
            <template #prefix>
              <NIcon>
                <FaIcon icon="fa-search" size="xs" />
              </NIcon>
            </template>
          </NInput>
        </div>
        <NDivider class="!mb-0 !mt-0" />
      </template>
    </slot>

    <!-- List -->
    <slot name="listContent" :items="filteredItems">
      <NScrollbar v-if="filteredItems.length" class="mt-1" :style="{ maxHeight: `${scrollHeight}px`, maxWidth }">
        <NSpace vertical :class="$attrs.class ? $attrs.class : 'pa-2'">
          <label v-if="label" class="font-500 c-gray-600 text-xs">{{ label }}</label>
          <NCheckbox
            v-for="(item, i) in filteredItems"
            :key="i"
            :checked="isChecked(item)"
            class="flex items-center"
            @update:checked="(checked: boolean) => onCheckboxUpdate(item, checked)"
          >
            <slot name="checkboxContent" :item="item">
              <div class="font-500">
                {{ item }}
              </div>
            </slot>
          </NCheckbox>
        </NSpace>
      </NScrollbar>

      <!-- loading -->
      <div v-else-if="loading" class="pa-2">
        <NSkeleton text :repeat="4" />
      </div>

      <!-- empty -->
      <div v-else-if="!filteredItems.length" class="text-subheader px-2 py-4 text-center text-sm">
        <slot name="no-data">
          {{ $t('common.no_data') }}
        </slot>
      </div>
    </slot>
  </NSpin>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

type TCheckboxListValue = string | number | Array<string | number>
type TCheckboxListItem = string | number | Record<string, unknown>

interface ICheckboxListProps {
  value?: TCheckboxListValue
  items: Array<string | number | Record<string, unknown>>
  // when used object arrays as items, key value to map values from
  valueKey?: string
  multiSelect?: boolean
  searchable?: boolean
  searchItemKey?: string
  searchClass?: string
  inputPlaceholder?: string
  loading?: boolean
  scrollHeight?: number
  maxWidth?: string
  label?: string
  sortSelected?: boolean
}

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<ICheckboxListProps>(), {
  items: () => [],
  valueKey: 'value',
  multiSelect: true,
  searchable: true,
  searchClass: '',
  loading: false,
  scrollHeight: 300,
  sortSelected: false,
})

const emit = defineEmits<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  'update:value': [value: any]
}>()

const searchKey = ref('')

const filteredItems = computed(() => {
  let items = [...props.items]

  if (searchKey.value !== '') {
    items = items.filter((item) => {
      // string array
      if (typeof item === 'string' || typeof item === 'number')
        return String(item).toLowerCase().includes(searchKey.value.toLowerCase())

      // object array
      if (!props.searchItemKey) {
        // eslint-disable-next-line
            let message = `searchItemKey prop is missing. When item is an object array, you need to define searchItemKey.\nAvailable keys: ${Object.keys(item).join(' | ')}`

        throw Error(message)
      }

      return String(item[props.searchItemKey]).toLowerCase().includes(searchKey.value.toLowerCase())
    })
  }

  if (props.sortSelected) {
    const unselectedItems = items.filter((item) => !isChecked(item))
    const valuesArray = Array.isArray(props.value) ? props.value : [props.value]
    const selectedItems = valuesArray
      .map((value) => items.find((item) => parseItemValue(item) === value))
      .filter((v) => v !== undefined)

    return [...selectedItems, ...unselectedItems]
  }

  return items
})

const validateValueKey = (item: object) => {
  if (!props.valueKey) {
    // eslint-disable-next-line
        throw Error(`You are using object array in items and value-key is missing!\nAvailable keys: ${Object.keys(item).join(' | ')}`)
  }
  if (props.valueKey && !(props.valueKey in item)) {
    // eslint-disable-next-line
        throw Error(`value-key: '${props.valueKey}' is not available in the object!\nAvailable keys: ${Object.keys(item).join(' | ')}`
    )
  }
  // valid
  return true
}

const parseItemValue = (item: TCheckboxListItem) => {
  return typeof item === 'object' && validateValueKey(item) ? item[props.valueKey as string] : (item as string | number)
}

const isChecked = (item: TCheckboxListItem) => {
  // props value is empty, no checked items
  if (!props.value) return false

  const itemValue = parseItemValue(item)

  return Array.isArray(props.value) ? props.value.includes(String(itemValue)) : itemValue === props.value
}

const onCheckboxUpdate = (item: TCheckboxListItem, checked: boolean) => {
  const currentValue = !props.value ? [] : (props.value as Array<string | number>)
  const itemValue = parseItemValue(item)

  if (props.multiSelect) {
    emit('update:value', checked ? [...currentValue, itemValue] : currentValue.filter((val) => itemValue !== val))
    return
  }
  emit('update:value', checked ? itemValue : undefined)
}
</script>

<style lang="scss" scoped></style>

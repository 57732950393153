import { library } from '@fortawesome/fontawesome-svg-core'
import type { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core'

// Regular icons
import {
  faArrowDown as farArrowDown,
  faArrowLeft as farArrowLeft,
  faArrowLeftToArc as farArrowLeftToArc,
  faArrowRight as farArrowRight,
  faArrowRightFromBracket as farArrowRightFromBracket,
  faArrowUp as farArrowUp,
  faArrowUpRightFromSquare as farArrowUpRightFromSquare,
  faArrowsRotate as farArrowsRotate,
  faBan as farBan,
  faBarChart as farBarChart,
  faBell as farBell,
  faBook as farBook,
  faBoxArchive as farBoxArchive,
  faBuilding as farBuilding,
  faCalendar as farCalendar,
  faChartColumn as farChartColumn,
  faChartLine as farChartLine,
  faChartLineDown as farChartLineDown,
  faChartLineUp as farChartLineUp,
  faChartLineUpDown as farChartLineUpDown,
  faChartPie as farChartPie,
  faChartSimple as farChartSimple,
  faChartTreeMap as farChartTreemap,
  faChevronLeft as farChevronLeft,
  faChevronRight as farChevronRight,
  faCircleChevronLeft as farCircleChevronLeft,
  faCircleChevronRight as farCircleChevronRight,
  faCircleExclamation as farCircleExclamation,
  faCircleInfo as farCircleInfo,
  faCircleNodes as farCircleNodes,
  faCircleQuestion as farCircleQuestion,
  faCircleT as farCircleT,
  faClose as farClose,
  faCog as farCog,
  faCoins as farCoins,
  faComment as farComment,
  faCommentDots as farCommentDots,
  faComments as farComments,
  faCopy as farCopy,
  faCrosshairsSimple as farCrosshairsSimple,
  faDatabase as farDatabase,
  faEllipsis as farEllipsis,
  faEllipsisVertical as farEllipsisVertical,
  faExclamationCircle as farExclamationCircle,
  faEye as farEye,
  faEyeSlash as farEyeSlash,
  faFaceThinking as farFaceThinking,
  faFileChartColumn as farFileChartColumn,
  faFileImport as farFileImport,
  faFileUpload as farFileUpload,
  faFilter as farFilter,
  faFilterList as farFilterList,
  faFrown as farFrown,
  faGauge as farGauge,
  faHeadset as farHeadset,
  faHeartCircleCheck as farHeartCircleCheck,
  faHourglassHalf as farHourglassHalf,
  faKeyboard as farKeyboard,
  faLinkHorizontal as farLinkHorizontal,
  faLinkHorizontalSlash as farLinkHorizontalSlash,
  faList as farList,
  faListTree as farListTree,
  faLock as farLock,
  faMeh as farMeh,
  faMessageLines as farMessageLines,
  faMessagePlus as farMessagePlus,
  faMessageText as farMessageText,
  faMicrochipAi as farMicrochipAi,
  faMinusCircle as farMinusCircle,
  faMoon as farMoon,
  faPaintbrush as farPaintBrush,
  faPaperPlaneTop as farPaperPlaneTop,
  faPen as farPen,
  faPenLine as farPenLine,
  faPieChart as farPieChart,
  faPizza as farPizza,
  faPlay as farPlay,
  faPlug as farPlug,
  faShieldHalved as farShieldHalved,
  faSmile as farSmile,
  faSparkles as farSparkles,
  faSpinnerThird as farSpinnerThird,
  faStar as farStar,
  faTags as farTags,
  faThumbTack as farThumbTack,
  faTrash as farTrash,
  faTrianglePersonDigging as farTrianglePersonDigging,
  faUpRightFromSquare as farUpRightFromSquare,
  faUser as farUser,
  faUserGroup as farUserGroup,
  faUserPlus as farUserPlus,
  faWallet as farWallet,
  faWifiExclamation as farWifiExclamation,
} from '@fortawesome/pro-regular-svg-icons'

// SOLID icons
import {
  faAngleRight,
  faArrowDown,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowRotateRight,
  faArrowUp,
  faArrowUpWideShort,
  faBells,
  faBoxArchive,
  faBug,
  faCaretUp,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleInfo,
  faCircleNodes,
  faCircleQuestion,
  faClose,
  faCog,
  faComment,
  faDash,
  faDatabase,
  faDownload,
  faEllipsis,
  faExpand,
  faFile,
  faFilter,
  faFolderClosed,
  faGear,
  faGripDotsVertical,
  faGripLines,
  faHeading,
  faHome,
  faInfo,
  faKeyboard,
  faLineColumns,
  faLock,
  faMerge,
  faMinus,
  faMobileScreenButton,
  faPaintBrush,
  faPen,
  faPenToSquare,
  faPlay,
  faPlug,
  faPlus,
  faQrcode,
  faRotateLeft,
  faRotateRight,
  faSearch,
  faShare,
  faShieldHalved,
  faSort,
  faSparkles,
  faStar,
  faSun,
  faTag,
  faTags,
  faThumbTack,
  faTrash,
  faUpload,
  faUser,
  faUserAlt,
  faUserCheck,
  faUserSecret,
  faWandMagicSparkles,
  faXmark,
} from '@fortawesome/pro-solid-svg-icons'

// LIGHT icons
import {
  faBinarySlash as falBinarySlash,
  faCoins as falCoins,
  faEmptySet as falEmptySet,
  faFaceThinking as falFaceThinking,
  faFileChartColumn as falFileChartColumn,
} from '@fortawesome/pro-light-svg-icons'

// THIN icons
import {
  faFileImport as fatFileImport,
  faFileUpload as fatFileUpload,
  faList as fatList,
  faLock as fatLock,
  faLockOpen as fatLockOpen,
  faStars as fatStars,
  faThumbsDown as fatThumbsDown,
  faThumbsUp as fatThumbsUp,
  faUpload as fatUpload,
} from '@fortawesome/pro-thin-svg-icons'

export type TIconName = `fa-${IconName}`
export type TIcon = TIconName | `${IconPrefix} ${TIconName}`

library.add(
  farBook,
  farCopy,
  farMessagePlus,
  farComments,
  farPaperPlaneTop,
  farPenLine,
  farCommentDots,
  farFileUpload,
  farUpRightFromSquare,
  farKeyboard,
  farCrosshairsSimple,
  faSparkles,
  farHeartCircleCheck,
  farMessageText,
  faHome,
  faBug,
  farWallet,
  farUserGroup,
  faFolderClosed,
  faAngleRight,
  faExpand,
  fatList,
  fatUpload,
  fatThumbsDown,
  fatThumbsUp,
  fatLockOpen,
  fatLock,
  farTags,
  fatFileImport,
  farFileImport,
  fatStars,
  fatFileUpload,
  farWifiExclamation,
  farExclamationCircle,
  falEmptySet,
  farCalendar,
  faMerge,
  farPen,
  farThumbTack,
  faCaretUp,
  faThumbTack,
  farSpinnerThird,
  farClose,
  faPenToSquare,
  faFile,
  faShare,
  falFileChartColumn,
  faDownload,
  faGear,
  faDatabase,
  farLinkHorizontal,
  farLinkHorizontalSlash,
  farMeh,
  farFaceThinking,
  farPizza,
  farFrown,
  farSmile,
  farCircleInfo,
  farCircleQuestion,
  farChartLine,
  farChartLineUp,
  farChartLineDown,
  farChartColumn,
  farMicrochipAi,
  farBarChart,
  farCircleChevronLeft,
  farCircleChevronRight,
  farChartLineUpDown,
  farChartTreemap,
  farMoon,
  farTrash,
  farStar,
  farCog,
  farArrowDown,
  farArrowLeft,
  farArrowRight,
  farArrowUp,
  farEllipsis,
  farFilter,
  farFilterList,
  farListTree,
  farGauge,
  farCircleT,
  farMinusCircle,
  farPieChart,
  farChartPie,
  farMessageLines,
  farList,
  farChevronLeft,
  farChevronRight,
  farDatabase,
  farChartSimple,
  farLock,
  farSparkles,
  farEye,
  farFileChartColumn,
  farArrowRightFromBracket,
  farBoxArchive,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  farBell,
  faMinus,
  faSun,
  faStar,
  faClose,
  faCircleInfo,
  faBoxArchive,
  faUserCheck,
  faUserSecret,
  faCircleQuestion,
  faUserAlt,
  faSearch,
  faArrowRotateRight,
  faChevronUp,
  faChevronLeft,
  faChevronRight,
  faEllipsis,
  faChevronDown,
  faFilter,
  faCog,
  faSort,
  faTag,
  faTags,
  faPlus,
  faPen,
  faTrash,
  faLock,
  faUpload,
  faMobileScreenButton,
  faQrcode,
  faArrowRightArrowLeft,
  faUser,
  faCheck,
  faXmark,
  faDash,
  farHourglassHalf,
  faGripDotsVertical,
  faGripLines,
  faKeyboard,
  faPaintBrush,
  faShieldHalved,
  faCircleNodes,
  faPlug,
  faWandMagicSparkles,
  faRotateLeft,
  faRotateRight,
  faHeading,
  faComment,
  farComment,
  faPlay,
  farPlay,
  farCoins,
  falCoins,
  farUserPlus,
  farBuilding,
  farUser,
  farPaintBrush,
  farShieldHalved,
  farCircleNodes,
  farPlug,
  faInfo,
  falFaceThinking,
  falBinarySlash,
  farArrowsRotate,
  farBan,
  farArrowUpRightFromSquare,
  faBells,
  faArrowUpWideShort,
  faArrowDownWideShort,
  farTrianglePersonDigging,
  farCircleExclamation,
  faLineColumns,
  farEllipsisVertical,
  farEyeSlash,
  farHeadset,
  farArrowLeftToArc
)

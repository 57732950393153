/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type TopicsOverviewInsightElementUpdateRequest = {
  kind?: 'TopicsOverviewInsight';
  order_id?: number;
  visible_left?: TopicsOverviewInsightElementUpdateRequest.visible_left;
  visible_right?: TopicsOverviewInsightElementUpdateRequest.visible_right;
};
export namespace TopicsOverviewInsightElementUpdateRequest {
  export enum visible_left {
    TOPICS_OVERVIEW_TREEMAP = 'topics_overview_treemap',
    TOPICS_OVERVIEW_SEGMENTED_BAR = 'topics_overview_segmented_bar',
  }
  export enum visible_right {
    TOPICS_SUMMARY = 'topics_summary',
    TOPIC_ROWS = 'topic_rows',
  }
}


<template>
  <NGrid cols="2" x-gap="8px" y-gap="8px">
    <!-- Loading skeletons -->
    <template v-if="!user">
      <NGi span="600:2 800:1">
        <NSpace vertical class="flex-1">
          <NSkeleton height="40px" :repeat="3" />
        </NSpace>
      </NGi>
      <NGi span="600:2 800:1">
        <NSpace vertical class="flex-1">
          <NSkeleton height="40px" :repeat="3" />
        </NSpace>
      </NGi>
    </template>

    <!-- Successfully fetched data -->
    <template v-else>
      <NGi span="600:2 800:1">
        <BigTextBanner
          class="shadow-sm"
          :title="$t('billing.your_plan')"
          :big-text="$t('billing.plan', { plan: user?.subscription.plan_name })"
        >
          <span v-html="nextRenewalInformation" />
        </BigTextBanner>
      </NGi>
      <NGi span="600:2 800:1">
        <StatsCard
          class="shadow-sm"
          :stats="[
            {
              label: $t('billing.stats.monthly'),
              value: user?.credits_remaining_recurring,
            },
            {
              label: $t('billing.stats.oneoff'),
              value: user?.credits_remaining_oneoff,
            },
          ]"
        />
      </NGi>
    </template>
  </NGrid>
</template>

<script lang="ts" setup>
import BigTextBanner from '@/components/BigTextBanner.vue'
import StatsCard from '@/components/StatsCard.vue'
import { computed } from 'vue'
import { formatDate } from '@/plugins/i18n'
import { useAuthStore } from '@/store'
import { useTranslate } from '@tolgee/vue'

const { user } = useAuthStore()

const { t } = useTranslate()

const nextRenewalInformation = computed(() => {
  if (user?.subscription.next_renewal_datetime) {
    return t.value('billing.stats.next_renewal_date', { date: formatDate(user.subscription.next_renewal_datetime) })
  } else if (user?.subscription.end) {
    return `${t.value('billing.stats.wont_be_renewed', { date: formatDate(user?.subscription.end) })}`
  } else {
    return t.value('billing.stats.billing_information_not_found')
  }
})
</script>

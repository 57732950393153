import {
  AvailableWidgetId,
  ColumnKind,
  type NestedCategoryCountPerSentiment,
  TopicsOverviewInsightElementResponse,
  WidgetKind,
} from '@/api'

export const topicsOverviewMock: TopicsOverviewInsightElementResponse = {
  id: 'ie_12452',
  kind: 'TopicsOverviewInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  order_id: 1,
  topics_overview_treemap: {
    id: 'wg_1',
    insight_element_id: 'ie_12452',
    name: 'Topics Overview treemap',
    kind: WidgetKind.TOPICS_OVERVIEW_TREEMAP,
    available_identifier: AvailableWidgetId.TOPICS_OVERVIEW_TREEMAP,
    quick_selectors: [],
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  topics_overview_segmented_bar: {
    id: 'wg_2',
    insight_element_id: 'ie_12452',
    name: 'Topics Overview segmented bar',
    kind: WidgetKind.TOPICS_OVERVIEW_SEGMENTED_BAR_CHART,
    quick_selectors: [],
    available_identifier: AvailableWidgetId.TOPICS_OVERVIEW_SEGMENTED_BAR,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  topics_summary: {
    id: 'wg_3',
    insight_element_id: 'ie_12452',
    name: 'Topics summary',
    kind: WidgetKind.TEXT,
    quick_selectors: [],
    available_identifier: AvailableWidgetId.TOPICS_SUMMARY,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  topic_rows: {
    id: 'wg_4',
    insight_element_id: 'ie_12452',
    name: 'Topic rows',
    kind: WidgetKind.TTA_COLUMN_ROWS,
    quick_selectors: [],
    available_identifier: AvailableWidgetId.TOPIC_ROWS,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
  },
  visible_left: TopicsOverviewInsightElementResponse.visible_left.TOPICS_OVERVIEW_TREEMAP,
  visible_right: TopicsOverviewInsightElementResponse.visible_right.TOPIC_ROWS,
  filters: [],
  available_filters: {},
}

// TO DO: minimize this
export const getSegmentedBarDataMock = (overallSegmentId: string): NestedCategoryCountPerSentiment => ({
  kind: 'nested-category-count-per-sentiment',
  results: {
    any: {
      [overallSegmentId]: [
        {
          id: 'FAST',
          label: 'FAST',
          value: 13.0,
          value_relative: 0.01314459049544995,
          topics: [
            {
              id: 'cd_1qo0mxg',
              label: 'speed of use',
              color: 'rgba(78, 78, 225, 0.6)',
              value: 13.0,
              value_relative: 0.01314459049544995,
            },
          ],
        },
        {
          id: 'EASE/CONVENIENCE',
          label: 'EASE/CONVENIENCE',
          value: 39.0,
          value_relative: 0.03943377148634985,
          topics: [
            {
              id: 'cd_9zxnm21',
              label: 'ease of doing things',
              color: 'rgba(75, 192, 192, 0.6)',
              value: 39.0,
              value_relative: 0.03943377148634985,
            },
          ],
        },
        {
          id: 'CUSTOMER SERVICE',
          label: 'CUSTOMER SERVICE',
          value: 114.0,
          value_relative: 0.11526794742163801,
          topics: [
            {
              id: 'cd_9rw7m6g',
              label: 'friendliness',
              color: 'rgba(255, 159, 64, 0.6)',
              value: 13.0,
              value_relative: 0.01314459049544995,
            },
            {
              id: 'cd_g4xkmq1',
              label: 'helpfulness',
              color: 'rgba(255, 159, 64, 0.6)',
              value: 102.0,
              value_relative: 0.10313447927199192,
            },
          ],
        },
        {
          id: 'BRAND',
          label: 'BRAND',
          value: 60.0,
          value_relative: 0.06066734074823053,
          topics: [
            {
              id: 'cd_1qo0m2g',
              label: 'familiarity with brand',
              color: 'rgba(153, 102, 255, 0.6)',
              value: 60.0,
              value_relative: 0.06066734074823053,
            },
          ],
        },
        {
          id: 'MISCELLANEOUS',
          label: 'MISCELLANEOUS',
          value: 195.0,
          value_relative: 0.19716885743174925,
          topics: [
            {
              id: 'cd_1jezyog',
              label: 'trustworthiness',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 49.0,
              value_relative: 0.04954499494438827,
            },
            {
              id: 'cd_10yrwvg',
              label: 'satisfaction',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 74.0,
              value_relative: 0.07482305358948432,
            },
            {
              id: 'cd_187n469',
              label: 'no issues',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 55.0,
              value_relative: 0.055611729019211326,
            },
            {
              id: 'cd_1vzl3yg',
              label: 'price',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 3.0,
              value_relative: 0.003033367037411527,
            },
            {
              id: 'cd_g7vwrmg',
              label: 'products',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 16.0,
              value_relative: 0.016177957532861477,
            },
            {
              id: 'cd_1qov7ng',
              label: 'test',
              color: 'rgba(54, 162, 235, 0.6)',
              value: 1.0,
              value_relative: 0.0010111223458038423,
            },
          ],
        },
        {
          id: 'COST',
          label: 'COST',
          value: 213.0,
          value_relative: 0.21536905965621841,
          topics: [
            {
              id: 'cd_gldx5n9',
              label: 'value for money',
              color: 'rgba(255, 99, 132, 0.6)',
              value: 10.0,
              value_relative: 0.010111223458038422,
            },
            {
              id: 'cd_93np2yg',
              label: 'price',
              color: 'rgba(255, 99, 132, 0.6)',
              value: 131.0,
              value_relative: 0.13245702730030334,
            },
            {
              id: 'cd_gmywm3g',
              label: 'rates',
              color: 'rgba(255, 99, 132, 0.6)',
              value: 64.0,
              value_relative: 0.06471183013144591,
            },
            {
              id: 'cd_1nz0m71',
              label: 'deals',
              color: 'rgba(255, 99, 132, 0.6)',
              value: 16.0,
              value_relative: 0.016177957532861477,
            },
            {
              id: 'cd_1dozxo9',
              label: 'ease of payment',
              color: 'rgba(255, 99, 132, 0.6)',
              value: 2.0,
              value_relative: 0.0020222446916076846,
            },
          ],
        },
        {
          id: 'ASSESSMENT OF US/COUN',
          label: 'ASSESSMENT OF US/COUN',
          value: 36.0,
          value_relative: 0.03640040444893832,
          topics: [
            {
              id: 'cd_g60xmzg',
              label: 'experience',
              color: 'rgba(121, 85, 72, 0.6)',
              value: 36.0,
              value_relative: 0.03640040444893832,
            },
          ],
        },
      ],
    },
  },
})

export const getTreemapDataMock = (overallSegmentId: string) =>
  getSegmentedBarDataMock(overallSegmentId).results.any[overallSegmentId]

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type StarRatingInsightElementUpdateRequest = {
  kind?: 'StarRatingInsight';
  order_id?: number;
  visible_left?: StarRatingInsightElementUpdateRequest.visible_left;
  visible_right?: StarRatingInsightElementUpdateRequest.visible_right;
};
export namespace StarRatingInsightElementUpdateRequest {
  export enum visible_left {
    STAR_BREAKDOWN = 'star_breakdown',
    STAR_SEGMENTED_BAR = 'star_segmented_bar',
  }
  export enum visible_right {
    STAR_OVER_TIME_LINE = 'star_over_time_line',
  }
}


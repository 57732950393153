/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MarkdownText } from './MarkdownText';
export type MarkdownTextInsightElementWidgetUpdateRequest = {
  widget_kind?: MarkdownTextInsightElementWidgetUpdateRequest.widget_kind;
  name?: string;
  static_content?: ('__not_specified__' | MarkdownText);
};
export namespace MarkdownTextInsightElementWidgetUpdateRequest {
  export enum widget_kind {
    MARKDOWN_TEXT = 'MarkdownText',
  }
}


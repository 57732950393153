<template>
  <DefaultLayout v-if="auth.isLoggedIn">
    <slot />
  </DefaultLayout>
  <div v-else class="shared-layout-container">
    <div class="capp-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import DefaultLayout from '@/views/_layouts/default.layout.vue'
import { useAuthStore } from '@/store'

const auth = useAuthStore()
</script>

<style scoped lang="scss">
.shared-layout-container {
  height: 100%;
  display: flex;
  overflow: hidden;

  .capp-content {
    flex: 1;
    height: calc(100vh - 72px);
    overflow-y: auto;
    scroll-behavior: smooth;
    border-radius: 0;
    padding: 36px;
    @apply bg-card-color;
  }
}
</style>

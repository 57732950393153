import {
  AvailableWidgetId,
  ColumnKind,
  type TopicTrendTableRowBySentiment,
  type TopicTrendsBySentiment,
  type TopicTrendsInDepthInsightElementResponse,
} from '@/api'
import { theme as uno } from '@unocss/preset-mini'

const randomRange = (min = 0, max = 100) => Math.floor(Math.random() * (max - min + 1)) + min
const getRandomDataPointObject = () => ({
  score: randomRange(0, 100),
  row_count: randomRange(),
  row_count_relative: randomRange() / 100,
})

export const topicTrendsInDepthMock: TopicTrendsInDepthInsightElementResponse = {
  id: 'topicTrendsInDepthMock_1',
  kind: 'TopicTrendsInDepthInsight',
  section_id: '1',
  order_id: 1,
  created: '2021-01-01',
  last_modified: '2021-01-01',
  overall_trending_chart: {
    id: 'overall_trending_chart_2',
    kind: 'TopicTrendsInDepth_BarChart',
    name: 'Overall Trending Topics',
    insight_element_id: '1',
    available_identifier: AvailableWidgetId.OVERALL_TRENDING_CHART,
    datasources: [
      {
        column_type: ColumnKind.TEXT_TO_ANALYZE,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  filters: [],
  available_filters: {},
}

export const MOCK_SEGMENT_ID = 'MOCK_SEGMENT_ID'

const colors = [uno.colors.amber[300], uno.colors.fuchsia[300], uno.colors.amber[300]]
const getColorIndex = () => Math.floor(Math.random() * colors.length)

const createRow = (id: string, category: string, label: string): TopicTrendTableRowBySentiment => ({
  id,
  category,
  label,
  values: new Array(8).fill('').map(() => ({
    any: getRandomDataPointObject(),
    positive: getRandomDataPointObject(),
    negative: getRandomDataPointObject(),
    neutral: getRandomDataPointObject(),
  })),
  change: getRandomDataPointObject(),
  color: colors[getColorIndex()],
  value: getRandomDataPointObject(),
  p_value: '0',
})

export const mockTopicTrendsIndepthData: TopicTrendsBySentiment = {
  kind: 'topic-trends-data-by-sentiment',
  results: [
    createRow('cd_18km5k1', 'OVERALL', 'Issues/problems'),
    createRow('cd_18km5k2', 'OVERALL', 'Recommendation'),
    createRow('cd_18km5k3', 'PRICE', 'Value for money'),
    createRow('cd_18km5k4', 'QUALITY', 'Screen'),
    createRow('cd_18km5k5', 'QUALITY', 'Audio'),
    createRow('cd_18km5k6', 'FEATURES', 'GPS'),
    createRow('cd_18km5k7', 'USAGE', 'Competitive comparison'),
    createRow('cd_18km5k8', 'USAGE', 'Ease of handling & navigation'),
    createRow('cd_18km5k9', 'OTHER', 'DO not share / recommend things'),
  ],
  tick_labels: ['Q1 2021', 'Q2 2021', 'Q3 2021', 'Q4 2021', 'Q1 2022', 'Q2 2022', 'Q3 2022', 'NOW'],
  timeframe: '',
}

import Intercom, { boot, onUnreadCountChange, show, shutdown, update } from '@intercom/messenger-js-sdk'
import type { IntercomSettings } from '@intercom/messenger-js-sdk/dist/types.js'

const INTERCOM_APP_IDS = {
  local: 'tj2c3k8q',
  production: 'uwrovuel',
}

let installed = false

export const intercom = {
  install(userData: Omit<IntercomSettings, 'app_id'>) {
    // do not install intercom in dev mode
    if (import.meta.env.DEV) return

    const intercomSettings: IntercomSettings = {
      app_id: import.meta.env.PROD ? INTERCOM_APP_IDS.production : INTERCOM_APP_IDS.local,
      hide_default_launcher: true,
      custom_launcher_selector: '.intercom-button',
      alignment: 'left',
      vertical_padding: 120,
      horizontal_padding: 40,
      ...userData,
    }

    // Initialize Intercom
    Intercom(intercomSettings)
    installed = true
  },
  boot: (userData: Omit<IntercomSettings, 'app_id'>) => {
    if (installed) {
      boot({
        ...userData,
        app_id: import.meta.env.PROD ? INTERCOM_APP_IDS.production : INTERCOM_APP_IDS.local,
      })
    }
  },
  shutdown: () => {
    if (installed) {
      shutdown()
    }
  },
  onUnreadCountChange: (callback: (unreadCount: number) => void) => {
    if (installed) {
      onUnreadCountChange(callback)
    }
  },
  show: () => {
    if (installed) {
      show()
    }
  },
  update: (userData: Omit<IntercomSettings, 'app_id'>) => {
    if (installed) {
      update({
        ...userData,
        app_id: import.meta.env.PROD ? INTERCOM_APP_IDS.production : INTERCOM_APP_IDS.local,
      })
    }
  },
}

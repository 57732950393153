import {
  AvailableWidgetId,
  ColumnKind,
  NpsScoreInsightElementResponse,
  SegmentKind,
  type SegmentedDataSeriesResponse,
  type SegmentedDataSeriesWithOverallResponse,
  WidgetKind,
} from '@/api'

export const npsInsightMock: NpsScoreInsightElementResponse = {
  id: 'ie_1',
  kind: 'NPSScoreInsight',
  section_id: 'sc_m3j39',
  created: '2024-03-06T16:01:52.231Z',
  last_modified: '2024-03-06T16:01:52.231Z',
  filters: [],
  available_filters: {},
  order_id: 1,
  nps_score_gauge: {
    id: 'wg_1',
    kind: WidgetKind.NPSSCORE_GAUGE_CHART,
    name: 'NPS Score Gauge',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_GAUGE,
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  nps_score_pie: {
    id: 'wg_2',
    kind: WidgetKind.NPSSCORE_PIE_CHART,
    name: 'NPS Score Pie',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_PIE,
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  nps_score_segmented_bar: {
    id: 'wg_3',
    kind: WidgetKind.NPSSCORE_SEGMENTED_BAR_CHART,
    name: 'NPS Segmented Bar',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_SCORE_SEGMENTED_BAR,
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  nps_over_time_line: {
    id: 'wg_4',
    kind: WidgetKind.NPSSCORE_LINE_CHART,
    name: 'NPS Over Time Line',
    insight_element_id: 'ie_1',
    available_identifier: AvailableWidgetId.NPS_OVER_TIME_LINE,
    datasources: [
      {
        column_type: ColumnKind.NUMERICAL,
        widget_id: 'wg_1',
      },
    ],
    quick_selectors: [],
  },
  visible_left: NpsScoreInsightElementResponse.visible_left.NPS_SCORE_GAUGE,
  visible_right: NpsScoreInsightElementResponse.visible_right.NPS_OVER_TIME_LINE,
}

export const mockSegmentsDict: Record<string, { name: string }> = {
  sg_1: {
    name: 'Segment 1',
  },
  sg_2: {
    name: 'Segment 2',
  },
  sg_3: {
    name: 'Segment 3',
  },
}

export const scoreWithScaleMock: SegmentedDataSeriesWithOverallResponse = {
  kind: 'segmented-data-series-with-overall',
  label: 'NPS Score',
  value: 0.6,
  tick_labels: ['detractors', 'passives', 'promoters'],
  values: [
    {
      segment_id: 'sg_1',
      segment_kind: SegmentKind.OVERALL,
      values: [
        {
          score: 20,
          row_count: 30.0,
          row_count_relative: 0.3,
        },
        {
          score: 50,
          row_count: 50.0,
          row_count_relative: 0.5,
        },
        {
          score: 60,
          row_count: 100.0,
          row_count_relative: 0.9,
        },
      ],
    },
    {
      segment_id: 'sg_2',
      segment_kind: SegmentKind.OVERALL,
      values: [
        {
          score: 10,
          row_count: 40.0,
          row_count_relative: 0.35,
        },
        {
          score: 60,
          row_count: 10.0,
          row_count_relative: 0.25,
        },
        {
          score: 20,
          row_count: 150.0,
          row_count_relative: 0.95,
        },
      ],
    },
  ],
}

export const timeSeriesMock: SegmentedDataSeriesResponse = {
  kind: 'segmented-data-series',
  tick_labels: [
    'Q1 2021',
    'Q2 2021',
    'Q3 2021',
    'Q4 2021',
    'Q1 2022',
    'Q2 2022',
    'Q3 2022',
    'Q4 2022',
    'Q1 2023',
    'Now',
  ],
  values: [
    {
      segment_id: 'sg_er50o52',
      segment_kind: SegmentKind.OVERALL,
      // values: [-25.0, -15.0, 17.0, 11.0, 22.0, 31.0, 1.0, -13.0, -15.0, 1.0, 8.0],
      values: [
        {
          score: 0.4,
          row_count: 25.0,
          row_count_relative: 0.25,
        },
        {
          score: 0.4,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.3,
          row_count: 11.0,
          row_count_relative: 0.11,
        },
        {
          score: 0.4,
          row_count: 22.0,
          row_count_relative: 0.22,
        },
        {
          score: 0.45,
          row_count: 31.0,
          row_count_relative: 0.31,
        },
        {
          score: 0.55,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.5,
          row_count: 13.0,
          row_count_relative: 0.13,
        },
        {
          score: 0.6,
          row_count: 15.0,
          row_count_relative: 0.15,
        },
        {
          score: 0.5,
          row_count: 1.0,
          row_count_relative: 0.1,
        },
        {
          score: 0.6,
          row_count: 8.0,
          row_count_relative: 0.8,
        },
      ],
    },
  ],
}

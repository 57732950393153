import App from './App.vue'
import VueVirtualScroller from 'vue-virtual-scroller'
import router from './router'
import { DynamicMDComponents } from './plugins/dynamicMDComponents'
import { VueQueryPlugin, vueQueryPluginOptions } from './plugins/vue-query'
import { VueTolgee } from '@tolgee/vue'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { tolgee } from './plugins/i18n'
import { usePosthog } from './plugins/posthog'
import { useUserpilot } from './plugins/userpilot'

import './style/global.scss'

import './plugins/font-awesome'
import 'md-editor-v3/lib/style.css'
import 'virtual:uno.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'

import { useMDEditorV3 } from './plugins/md-editor-v3'
import { useSentry } from './plugins/sentry'

const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueTolgee, { tolgee })
app.use(VueVirtualScroller)
app.use(VueQueryPlugin, vueQueryPluginOptions)
app.use(DynamicMDComponents)

if (
  import.meta.env.PROD &&
  (import.meta.env.MODE === 'production' || import.meta.env.MODE === 'stage' || import.meta.env.MODE === 'development')
) {
  useSentry(app, router)
  usePosthog(app, router)
  useUserpilot(router)
}

useMDEditorV3()

app.mount('#caplena-app')

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type NpsScoreWoTimeInsightElementUpdateRequest = {
  kind?: 'NPSScoreWoTimeInsight';
  order_id?: number;
  visible_left?: NpsScoreWoTimeInsightElementUpdateRequest.visible_left;
  visible_center?: NpsScoreWoTimeInsightElementUpdateRequest.visible_center;
  visible_right?: NpsScoreWoTimeInsightElementUpdateRequest.visible_right;
};
export namespace NpsScoreWoTimeInsightElementUpdateRequest {
  export enum visible_left {
    NPS_SCORE_GAUGE = 'nps_score_gauge',
    NPS_SCORE_PIE = 'nps_score_pie',
    NPS_SCORE_SEGMENTED_BAR = 'nps_score_segmented_bar',
  }
  export enum visible_center {
    PROMOTERS_ROWS = 'promoters_rows',
    PROMOTERS_SUMMARY = 'promoters_summary',
  }
  export enum visible_right {
    DETRACTORS_ROWS = 'detractors_rows',
    DETRACTORS_SUMMARY = 'detractors_summary',
  }
}


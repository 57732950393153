export const randomRange = (min = -100, max = 100) => Math.floor(Math.random() * (max - min + 1)) + min

const mockColors = [
  'rgba(54,162,235,0.6)',
  'rgba(75,192,192,.6)',
  'rgba(78,78,225,.6)',
  'rgba(153,102,255,.6)',
  'rgba(121,85,72,.6)',
  'rgba(153,102,255,.6)',
]

export const randomColor = () => mockColors[randomRange(0, 5)]

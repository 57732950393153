/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum ColumnKind {
  NULL = 'null',
  TEXT_TO_ANALYZE = 'text_to_analyze',
  TEXT = 'text',
  NUMERICAL = 'numerical',
  DATE = 'date',
  BOOLEAN = 'boolean',
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum AvailableWidgetId {
  CATEGORICAL_TEXT_TREEMAP = 'categorical_text_treemap',
  CATEGORICAL_TEXT_BAR = 'categorical_text_bar',
  DRIVER_SUMMARY = 'driver_summary',
  DRIVER_INFLUENCE_ANALYSIS = 'driver_influence_analysis',
  DRIVER_ANALYSIS = 'driver_analysis',
  NPS_SCORE_GAUGE = 'nps_score_gauge',
  NPS_SCORE_PIE = 'nps_score_pie',
  NPS_SCORE_SEGMENTED_BAR = 'nps_score_segmented_bar',
  NPS_OVER_TIME_LINE = 'nps_over_time_line',
  NPS_SCORE_BREAKDOWN = 'nps_score_breakdown',
  PROMOTERS_ROWS = 'promoters_rows',
  PROMOTERS_SUMMARY = 'promoters_summary',
  DETRACTORS_ROWS = 'detractors_rows',
  DETRACTORS_SUMMARY = 'detractors_summary',
  SENTIMENT_SCORE_GAUGE = 'sentiment_score_gauge',
  SENTIMENT_SCORE_SEGMENTED_BAR_CHART = 'sentiment_score_segmented_bar_chart',
  SENTIMENT_SCORE_OVER_TIME_LINE = 'sentiment_score_over_time_line',
  SENTIMENT_SCORE_OVER_TIME_BAR = 'sentiment_score_over_time_bar',
  OVERALL_ROWS = 'overall_rows',
  OVERALL_NPS_SCORE = 'overall_nps_score',
  OVERALL_RECOMMEND = 'overall_recommend',
  OVERALL_RATING = 'overall_rating',
  OVERALL_ITEMS = 'overall_items',
  PASSIVES_ROWS = 'passives_rows',
  PASSIVES_SUMMARY = 'passives_summary',
  STAR_BREAKDOWN = 'star_breakdown',
  STAR_SEGMENTED_BAR = 'star_segmented_bar',
  STAR_OVER_TIME_LINE = 'star_over_time_line',
  MARKDOWN_TEXT_LEFT = 'markdown_text_left',
  MARKDOWN_TEXT_CENTER = 'markdown_text_center',
  MARKDOWN_TEXT_RIGHT = 'markdown_text_right',
  TOPIC_BREAKOUT_HEATMAP = 'topic_breakout_heatmap',
  SENTIMENT_PER_TOPIC = 'sentiment_per_topic',
  OVERALL_TRENDING_CHART = 'overall_trending_chart',
  TRENDING_DATA = 'trending_data',
  TOPICS_OVERVIEW_TREEMAP = 'topics_overview_treemap',
  TOPICS_OVERVIEW_SEGMENTED_BAR = 'topics_overview_segmented_bar',
  TOPICS_SUMMARY = 'topics_summary',
  TOPIC_ROWS = 'topic_rows',
}

/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum SegmentKind {
  OVERALL = 'overall',
  USER_DEFINED = 'user-defined',
}

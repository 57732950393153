/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type ApiException404 = {
  /**
   * Type of the error returned.
   */
  type: ApiException404.type;
  /**
   * Short string that uniquely identifies the reason for this error. Useful for programmatic error handling.
   */
  code: ApiException404.code;
  /**
   * Brief human-readable message providing more details about the error that has occurred. Can be shown to end users.
   */
  message: string;
  /**
   * Lengthier human-readable explanation of the error. This property is intended for use by developers only and provides additional information on how this issue could be resolved.
   */
  details?: string;
  /**
   * URL that links to part of our developer documentation, allowing engineers to learn more about how to fix the given issue.
   */
  help?: string;
  /**
   * Context that contains additional information about why this error occurred specifically. It is always an object, but its properties differ depending on the `code` field. For a given error with the same `code` fields, its object structure is equivalent.
   */
  context?: Record<string, any>;
};
export namespace ApiException404 {
  /**
   * Type of the error returned.
   */
  export enum type {
    NOT_FOUND_ERROR = 'not_found_error',
  }
  /**
   * Short string that uniquely identifies the reason for this error. Useful for programmatic error handling.
   */
  export enum code {
    RESOURCE_MISSING = 'resource_missing',
  }
}


<template>
  <!-- TO DO: translate -->
  <NResult
    status="403"
    title="Oops! You are not authorized to perform this action."
    description="You know life is sometimes ridiculous."
  >
    <template #footer>
      <RouterLink v-slot="{ navigate }" to="/projects">
        <NButton type="primary" @click="navigate">Take me to the Projects</NButton>
      </RouterLink>
    </template>
  </NResult>
</template>

<template>
  <!-- error -->
  <DataError v-if="isError && !isFetching" :title="error?.name" :message="error?.message" @click:refetch="refetch" />
  <!-- presentation -->
  <NGrid v-else cols="12">
    <NGi span="12">
      <div class="flex items-center justify-between">
        <NH2 class="mb-0">{{ $t('navigation.projects') }}</NH2>
        <div class="flex items-center gap-2">
          <!-- TO DO: translate -->
          <NCheckbox
            :checked="queryOptions.archived === 'True'"
            class="flex items-center"
            label="Show archived"
            @update:checked="handleShowArchivedChange"
          />
          <SearchInput v-if="!hideSearch" v-model:value="searchKey" @update:value="handleSearchUpdate" />
        </div>
      </div>
      <ProjectsTableActions
        :selected-projects="selectedProjects"
        :item-count="pagination.itemCount"
        :available-tags="availableTags"
        @click:clear-selection="handleRowCheck([])"
      >
        <Container flex class="!rounded-20px">
          <DropdownFilter :filters-config="tableFiltersConfig" @update:filters="handleFiltersUpdate" />
          <SelectedFilters
            :filters-config="tableFiltersConfig"
            @update:filters="handleFiltersUpdate"
            @click:reset="setSearchAndFilters('reset')"
          />
        </Container>
      </ProjectsTableActions>

      <NDivider />

      <Container>
        <div class="mb-1 flex items-center justify-between px-2 py-1">
          <!-- TO DO: translate -->
          <div class="font-500">Matching projects: {{ data?.count }}</div>

          <!-- TO DO: to add functionality to this button -->
          <!-- TO DO: Enable later once it is functional -->
          <NButton v-if="canUserSeeDebugFeatures" type="primary" size="small" icon class="ml-auto">
            {{ $t('projects.new_project') }}
            <FaIcon icon="fa-plus" class="ml-2" />
          </NButton>
        </div>
        <NDataTable
          v-model:checked-row-keys="checkedRowIDs"
          remote
          :loading="isLoading || isPlaceholderData"
          :columns="headers"
          :data="data?.results"
          :row-props="tableRowProps"
          :row-key="tableRowKey"
          :pagination="pagination"
          table-layout="fixed"
          row-class-name="text-sm"
          scroll-x="1024"
          @update-sorter="handleSorterChange"
          @update:checked-row-keys="handleRowCheck"
        >
          <template #empty>
            <DataEmpty
              :title="$t('projects.no_projects.title')"
              :message="$t('projects.no_projects.details')"
              @click:reset="setSearchAndFilters('reset')"
            />
          </template>
        </NDataTable>
      </Container>
    </NGi>
  </NGrid>
</template>

<script setup lang="ts">
import DataEmpty from '@/components/DataEmpty.vue'
import DataError from '@/components/DataError.vue'
import ProjectListTableTags from './ProjectsTableTags.vue'
import ProjectsTableActions from './ProjectsTableActions.vue'
import SearchInput from '@/components/SearchInput.vue'
import { type ArgTypes, useProjectListConfigQuery } from '@/api/vq/projects'
import { type DataTableColumns, type DataTableCreateRowProps, type DataTableRowKey } from 'naive-ui'
import { DropdownFilter, SelectedFilters } from '@/components/filters'
import { RouterLink, useRouter } from 'vue-router'
import { canUserSeeDebugFeatures } from '@/plugins/posthog'
import { computed, h, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { formatDate, formatTime } from '@/plugins/i18n'
import { renderSorterIcon, renderTableTH } from '@/utils'
import { useProjectsDataTable } from '../helpers'
import { useTranslate } from '@tolgee/vue'
import { useUrlSearchParams } from '@vueuse/core'
import type { ProjectListUIResponse } from '@/api'

interface IProjectsTableProps {
  /* disables reading values from URLparams in case it is used in somewhere else e.g. modal or sth similar */
  disableUrlParams?: boolean
  hideSearch?: boolean
}

const props = defineProps<IProjectsTableProps>()

const DEFAULT_LIMIT = 9

const { t } = useTranslate()
const router = useRouter()
const URLParams: ArgTypes<'projectList'> | undefined = !props.disableUrlParams
  ? useUrlSearchParams('history')
  : undefined
const searchKey = ref(URLParams?.search)
const hoveredProjectID = ref()
const checkedRowIDs = ref<DataTableRowKey[]>([])
const shouldPushRouterState = ref(true)
const orderBy = ref(URLParams?.orderBy)

const {
  tableFiltersConfig,
  data,
  isFetching,
  isLoading,
  isError,
  error,
  isPlaceholderData,
  pagination,
  refetch,
  handleFiltersUpdate,
  handleSorterChange,
  handleSearchUpdate,
  handleShowArchivedChange,
  queryOptions,
} = useProjectsDataTable(URLParams)

const { data: configData } = useProjectListConfigQuery()
const availableTags = computed(
  () => configData.value?.tabs.filter((tab) => tab.type === 'tag').sort((a, b) => a.name.localeCompare(b.name)) || []
)

const headers = computed<DataTableColumns<ProjectListUIResponse>>(() => [
  {
    type: 'selection',
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.name')),
    key: 'name',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    className: 'cursor-pointer !p-0',
    width: 300,
    ellipsis: {
      tooltip: true,
      class: 'w-full h-full',
    },
    render: (p) =>
      h(RouterLink, { to: `/projects/${p.id}`, class: 'block px-2 h-full' }, () => [
        h('div', { class: 'c-header-text-color font-500' }, p.name),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, p.owner_name),
      ]),
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.date_modified')),
    key: 'last_modified',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    width: 150,
    render: (p) =>
      h('div', null, [
        h('div', { class: 'font-500' }, formatDate(p.last_modified) || '-'),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, formatTime(p.last_modified) || '-'),
      ]),
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.date_created')),
    key: 'created',
    sorter: 'default',
    renderSorterIcon: ({ order }) => renderSorterIcon(order),
    width: 150,
    render: (p) =>
      h('div', null, [
        h('div', { class: 'font-500' }, formatDate(p.created) || '-'),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, formatTime(p.created) || '-'),
      ]),
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.language')),
    key: 'language',
    width: 100,
    render: (p) => h('div', { class: 'font-500 c-gray-800' }, t.value(`common.language.${p.language}`)),
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.source')),
    key: 'data_source_provider',
    width: 150,
    render: (p) => h('div', { class: 'font-500 c-gray-800' }, t.value(`common.integrations.${p.data_source_provider}`)),
  },
  // TODO: translate here
  {
    title: () => renderTableTH(t.value('projects.datatable.rows')),
    key: 'rows_count',
    width: 150,
    render: (p) =>
      h('div', null, [
        h('div', { class: 'font-500' }, `${p.questions_count} Text Column`),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, `${p.rows_count} Rows`),
      ]),
  },
  {
    title: () => renderTableTH(t.value('projects.datatable.tags')),
    key: 'actions__tags',
    minWidth: 220,
    render: (p) =>
      h(ProjectListTableTags, {
        project: p,
        hovered: p.id === hoveredProjectID.value,
        availableTags: availableTags.value,
      }),
  },
])

const selectedProjects = computed(() => (data.value?.results || []).filter((r) => checkedRowIDs.value.includes(r.id)))

const tableRowKey = (row: ProjectListUIResponse) => row.id
const tableRowProps: DataTableCreateRowProps<ProjectListUIResponse> = (p) => ({
  onMouseenter: () => (hoveredProjectID.value = p.id),
  onMouseleave: () => (hoveredProjectID.value = undefined),
})
const handleRowCheck = (rowKeys: DataTableRowKey[]) => {
  checkedRowIDs.value = rowKeys
}

const setSearchAndFilters = async (to: 'params' | 'reset' = 'params') => {
  // prevent to be triggered when user navigates back
  if (to === 'params') shouldPushRouterState.value = false

  // set orderBy
  orderBy.value = URLParams?.orderBy

  // Do not include in reset or set with specific defaults
  queryOptions.tab = to === 'params' ? URLParams?.tab : queryOptions.tab
  queryOptions.limit = to === 'params' ? Number(URLParams?.limit) || DEFAULT_LIMIT : queryOptions.limit
  queryOptions.page = to === 'params' ? Number(URLParams?.page) || 1 : 1
  searchKey.value = to === 'params' ? URLParams?.search || '' : ''

  // included in reset
  queryOptions.orderBy = to === 'params' ? URLParams?.orderBy : undefined
  queryOptions.search = to === 'params' ? URLParams?.search : undefined
  queryOptions.labels = to === 'params' ? URLParams?.labels : undefined
  queryOptions.owner = to === 'params' ? URLParams?.owner : undefined
  queryOptions.created = to === 'params' ? URLParams?.created : undefined
  queryOptions.lastModified = to === 'params' ? URLParams?.lastModified : undefined
  queryOptions.language = to === 'params' ? URLParams?.language : undefined
  queryOptions.dataSourceProvider = to === 'params' ? URLParams?.dataSourceProvider : undefined

  // await refetch()
  // NOTE: Important that it comes after fetch
  if (to === 'params') shouldPushRouterState.value = true
}

onMounted(() => {
  window.addEventListener('popstate', () => setSearchAndFilters('params'))
})

onBeforeUnmount(() => {
  window.removeEventListener('popstate', () => setSearchAndFilters('params'))
})

// queryOptions watcher to update URL query when an update is done
watch(queryOptions, (newVal) => {
  if (!URLParams) return

  // 1 is the default page, no need to set it as URL param
  URLParams.page = newVal.page === 1 ? undefined : newVal.page
  // 9 is default, no need to set it as URL param
  URLParams.limit = newVal.limit === DEFAULT_LIMIT ? undefined : newVal.limit
  // filter:all_projects is the default, no need to set it as URL param
  URLParams.tab = newVal.tab === 'filter:all_projects' ? undefined : newVal.tab
  URLParams.archived = newVal.archived
  URLParams.search = newVal.search
  URLParams.orderBy = newVal.orderBy
  URLParams.labels = newVal.labels
  URLParams.owner = newVal.owner
  URLParams.created = newVal.created
  URLParams.lastModified = newVal.lastModified
  URLParams.language = newVal.language
  URLParams.dataSourceProvider = newVal.dataSourceProvider

  // prevent to be triggered when user navigates back
  if (shouldPushRouterState.value) {
    router.push({ name: 'projects', query: URLParams })
  }
})
</script>

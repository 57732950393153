/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
/**
 * An enumeration.
 */
export enum DebounceDelay {
  SYNC = 'sync',
  DEFAULT = 'default',
  LONG_DELAY = 'long_delay',
}

const getUID = () => {
  return Math.random().toString(36).substring(2, 6) + Math.random().toString(36).substring(2, 6)
}

export const uid = (times = 1, prefix = '') => {
  let uid = prefix

  for (let i = 0; i < times; i++) {
    uid += i > 0 ? `-${getUID()}` : `${getUID()}`
  }

  return uid
}

import Duplicate from '@/assets/icons/duplicate.svg?raw'
import Expand from '@/assets/icons/expand.svg?raw'
import HighFrequency from '@/assets/icons/medium-frequency.svg?raw'
import LowFrequency from '@/assets/icons/low-frequency.svg?raw'
import MediumFrequency from '@/assets/icons/high-frequency.svg?raw'
import NoSentiment from '@/assets/icons/no-sentiment.svg?raw'
import SentimentAny from '@/assets/icons/sentiment-any.svg?raw'
import SentimentNegative from '@/assets/icons/sentiment-negative.svg?raw'
import SentimentNeutral from '@/assets/icons/sentiment-neutral.svg?raw'
import SentimentPositive from '@/assets/icons/sentiment-positive.svg?raw'

declare global {
  type TCustomIconName = (typeof ICON_NAMES)[number]
}

type TSVGIconLibrary = {
  [key in TCustomIconName]: string
}

// icon name list
// all icons under icons/ folder must be declared here after import
const ICON_NAMES = [
  'sentiment-any',
  'sentiment-negative',
  'sentiment-neutral',
  'sentiment-positive',
  'expand',
  'duplicate',
  'no-sentiment',
  'low-frequency',
  'medium-frequency',
  'high-frequency',
] as const

// all icons under icons/ folder must be declared here after declared in ICON_NAMES
const library: TSVGIconLibrary = {
  duplicate: Duplicate,
  expand: Expand,
  'sentiment-any': SentimentAny,
  'sentiment-negative': SentimentNegative,
  'sentiment-neutral': SentimentNeutral,
  'sentiment-positive': SentimentPositive,
  'no-sentiment': NoSentiment,
  'low-frequency': LowFrequency,
  'medium-frequency': HighFrequency,
  'high-frequency': MediumFrequency,
}

export default library

import { AuxiliaryFieldUIResponse, TextToAnalyzeFieldUIResponse } from '@/api'

export const parseQuestions = (columns: Array<TextToAnalyzeFieldUIResponse | AuxiliaryFieldUIResponse>) => {
  const analyze: TextToAnalyzeFieldUIResponse[] = []
  const auxiliary: AuxiliaryFieldUIResponse[] = []

  columns.forEach((col) => {
    if (col.type === TextToAnalyzeFieldUIResponse.type.TEXT_TO_ANALYZE) {
      analyze.push(col)
      return
    }

    auxiliary.push(col)
  })

  return { analyze, auxiliary }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import isPostalCode from 'validator/es/lib/isPostalCode.js'
import { addMethod, string } from 'yup'
import { tolgee } from '@/plugins/i18n'

// add postal code rule to strings
addMethod(string, 'postalCode', function postalCode() {
  return this.test('postal-code', function testFunction(value) {
    if (!value) return true

    return (
      isPostalCode(value, 'any') || this.createError({ path: this.path, message: tolgee.t('formValidation.postCode') })
    )
  })
})

/* commonly used rules in the application */
export const phoneNumberRule = () =>
  string()
    .min(9)
    .matches(/^\+\d{5,15}$/, tolgee.t('formValidation.phoneNumber'))
export const passwordRule = () => string().required(tolgee.t('formValidation.required')).min(6)
export const nameRule = () => string().required(tolgee.t('formValidation.required')).min(3)
export const authCodeRule = () => string().required(tolgee.t('formValidation.required')).length(6)

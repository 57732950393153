import {
  API,
  AccountService,
  ApiError,
  AuthService,
  type LoginRequest,
  LoginResponse,
  type ResetPasswordRequest,
  ThemeService,
  type UserDetailResponse,
  type UserDetailViewPermissionsResponse,
} from '@/api'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { identifyPosthogUser, isFeatureEnabled, resetPosthogUser } from '@/plugins/posthog'
import { identifyUserPilot } from '@/plugins/userpilot'
import { identifyUserSentry } from '@/plugins/sentry'
import { intercom } from '@/plugins/intercom'
import { queryClient } from '@/plugins/vue-query'
import { tolgee } from '@/plugins/i18n'
import { useThemeStore } from './theme'

const sudoTokenValidMins = 15

let userIdPrefix = 'dev'

if (import.meta.env.DEV) userIdPrefix = 'local'
else if (import.meta.env.MODE === 'production') userIdPrefix = 'prod'
else if (import.meta.env.MODE === 'stage') userIdPrefix = 'stage'

export const useAuthStore = defineStore('auth', () => {
  // state
  const user = ref<UserDetailResponse | null>(null)
  const isLoggedIn = computed(() => !!user.value)
  const hasOrganization = computed(() => !!user.value?.organization)
  const isOrganizationOwner = computed(() => user.value?.organization?.root_user_id === user.value?.id)
  const userLanguage = computed<TLanguage['value']>(
    () => (user.value?.language || localStorage.getItem('language') || 'en') as TLanguage['value']
  )

  const sudoToken = ref<string | undefined>()
  const sudoTokenCreated = ref<number | undefined>()

  // other dependencies
  const theme = useThemeStore()

  // permission helper function
  const hasPermission = (permission: keyof UserDetailViewPermissionsResponse) => {
    if (!user.value) return false
    if (!user.value.organization) return true
    if (user.value.organization.root_user_id === user.value.id) return true
    if (user.value.view_permissions && user.value.view_permissions[permission]) return true

    return false
  }

  // actions
  async function getUser() {
    try {
      const res = await AccountService.userDetail()

      user.value = res

      // post login/user available actions
      identifyUserSentry(user.value)
      await identifyPosthogUser(user.value.id)
      identifyUserPilot(user.value, userIdPrefix)

      // get & set theme
      theme.setThemeValuesFromAPI(await ThemeService.themeRetrieve())

      // Boot Intercom with user data
      if (isFeatureEnabled('intercom')) {
        // install intercom and boot it with user data
        const intercomUserData = {
          name: `${user.value.first_name} ${user.value.last_name}`,
          email: user.value.email,
          created_at: new Date(user.value.date_joined).getTime() / 1000,
          user_id: `${userIdPrefix}_${user.value.id}`,
          language_override: tolgee.getLanguage(),
          user_hash: user.value.intercom_user_hash,
        }

        intercom.install(intercomUserData)
        intercom.boot(intercomUserData)
      }

      return user.value
    } catch {
      // shutdown intercom if error
      intercom.shutdown()
      return null
    }
  }

  async function updateUser(userData: Partial<UserDetailResponse>) {
    let res

    try {
      res = await AccountService.userUpdate({ requestBody: userData })

      // update user data after mutation
      user.value = res
      // invalidate config query since to trigger re-fetch after user changes
      queryClient.invalidateQueries({ queryKey: ['config'] })

      // Update Intercom with new user data
      intercom.update({
        name: `${user.value.first_name} ${user.value.last_name}`,
        email: user.value.email,
        user_id: `${userIdPrefix}_${user.value.id}`,
        language_override: tolgee.getLanguage(),
        user_hash: user.value.intercom_user_hash,
      })
    } catch (e: unknown) {
      return null
    }

    return res
  }

  async function reset({ email }: ResetPasswordRequest) {
    try {
      return AuthService.userResetPasswordRequest({ requestBody: { email } })
    } catch (err) {
      return { error: err as ApiError }
    }
  }

  async function login({ email, password, device_id, token }: LoginRequest) {
    const postData = { email, password, device_id, token }

    try {
      const res = await AuthService.login({ requestBody: postData })

      switch (res.next_step) {
        case LoginResponse.next_step.SSO:
          if (res.redirect_to) return window.location.replace(res.redirect_to)
          return { nextStep: res.next_step }
        case LoginResponse.next_step._2FA_DEVICE:
          return { nextStep: res.next_step, devices: res.devices }
        case LoginResponse.next_step._2FA_TOKEN:
          return { nextStep: res.next_step, devices: res.devices, device: res.device }
        default:
          return { nextStep: res.next_step }
      }
    } catch (err) {
      return { error: err as ApiError }
    }
  }

  // TO DO: replace with generated API
  async function logout() {
    try {
      await API.get('/auth/logout')
    } catch {
      console.error('Something went wrong when trying to logout')
    } finally {
      document.cookie = `csrftoken=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`
      user.value = null
      identifyUserSentry(null)
      resetPosthogUser()
      intercom.shutdown()
    }
  }

  // TO DO: replace with generated API
  async function CSRFTest() {
    try {
      const { data } = await API.post('/csrftest', {}, { dontReport: [401, 403] })

      return data === 'ok'
    } catch {
      return false
    }
  }

  function disableSudoMode() {
    sudoToken.value = undefined
    sudoTokenCreated.value = undefined
    delete API.defaults.headers.common['X-Sudo-Token']
    clearTimeout(window.sudoTimeout)
  }

  async function enableSudoMode(password: string) {
    try {
      const { token } = await AuthService.sudoMode({ requestBody: { password } })

      // save token to state & the headers
      sudoToken.value = token
      sudoTokenCreated.value = new Date().getTime()
      API.defaults.headers.common['X-Sudo-Token'] = token

      // cleanup timeout if exist & start timeout
      if (window.sudoTimeout) clearTimeout(window.sudoTimeout)
      window.sudoTimeout = setTimeout(disableSudoMode, sudoTokenValidMins * 60 * 1000)

      return { token }
    } catch (err) {
      return { error: err as ApiError }
    }
  }

  function isInSudoMode() {
    // valid case
    if (sudoToken.value && sudoTokenCreated.value) {
      const now = new Date().getTime()
      const minuteDiff = Math.abs((now - sudoTokenCreated.value) / 1000 / 60)

      return minuteDiff < sudoTokenValidMins
    }

    // cleanup if invalid
    disableSudoMode()
    return false
  }

  return {
    user,
    isLoggedIn,
    hasOrganization,
    isOrganizationOwner,
    userLanguage,
    sudoToken,
    sudoTokenCreated,
    isInSudoMode,
    hasPermission,
    getUser,
    login,
    logout,
    CSRFTest,
    updateUser,
    enableSudoMode,
    reset,
  }
})

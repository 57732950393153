/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DiscardedTopicRequest } from '../models/DiscardedTopicRequest';
import type { DiscardedTopicResponse } from '../models/DiscardedTopicResponse';
import type { DiscardedTopicsResponse } from '../models/DiscardedTopicsResponse';
import type { TopicerDummyResponse } from '../models/TopicerDummyResponse';
import type { TopicerNewRequest } from '../models/TopicerNewRequest';
import type { TopicerNewResponse } from '../models/TopicerNewResponse';
import type { TopicerSuggestionsRequest } from '../models/TopicerSuggestionsRequest';
import type { TopicerSuggestionsResponse } from '../models/TopicerSuggestionsResponse';
import type { UpdateRawTopicCollectionResponse } from '../models/UpdateRawTopicCollectionResponse';
import type { UpdateRawTopicCollectionStatusResponse } from '../models/UpdateRawTopicCollectionStatusResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TopicerService {
  /**
   * Generates, persists and returns a new topic collection for a question. A raw topic collection must be computed already
   * Generates, persists and returns a new topic collection for a question. A raw topic collection must be computed already
   * @returns TopicerNewResponse OK
   * @throws ApiError
   */
  public static generateTopicCollection({
    projectId,
    ref,
    requestBody,
  }: {
    projectId: string,
    ref: string,
    requestBody: TopicerNewRequest,
  }): CancelablePromise<TopicerNewResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/topicer/projects/{project_id}/columns/{ref}/topic-collection',
      path: {
        'project_id': projectId,
        'ref': ref,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Generates and return suggestions to imporve topic collection
   * Generates and return suggestions for new candidate topics. A raw topic collection must be computed already
   * @returns TopicerSuggestionsResponse OK
   * @throws ApiError
   */
  public static generateSuggestions({
    projectId,
    ref,
    requestBody,
  }: {
    projectId: string,
    ref: string,
    requestBody: TopicerSuggestionsRequest,
  }): CancelablePromise<TopicerSuggestionsResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/topicer/projects/{project_id}/columns/{ref}/suggestions',
      path: {
        'project_id': projectId,
        'ref': ref,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        422: `Unprocessable Entity`,
      },
    });
  }
  /**
   * Update raw topic collection
   * Update raw topic collection
   * @returns UpdateRawTopicCollectionResponse Accepted
   * @throws ApiError
   */
  public static updateRawTopicCollectionAsync({
    projectId,
    ref,
  }: {
    projectId: string,
    ref: string,
  }): CancelablePromise<UpdateRawTopicCollectionResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/topicer/projects/{project_id}/columns/{ref}/update-raw-topic-collection-async',
      path: {
        'project_id': projectId,
        'ref': ref,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Check topicer status task collection
   * Check topicer status task collection
   * @returns UpdateRawTopicCollectionStatusResponse OK
   * @throws ApiError
   */
  public static topicerAsyncTaskStatus({
    projectId,
    ref,
    taskUuid,
  }: {
    projectId: string,
    ref: string,
    taskUuid: string,
  }): CancelablePromise<UpdateRawTopicCollectionStatusResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/topicer/projects/{project_id}/columns/{ref}/update-raw-topic-collection-async/{task_uuid}',
      path: {
        'project_id': projectId,
        'ref': ref,
        'task_uuid': taskUuid,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Fetch discarded topics
   * Fetch discarded topics
   * @returns DiscardedTopicsResponse OK
   * @throws ApiError
   */
  public static listDiscardedTopics({
    projectId,
    ref,
  }: {
    projectId: string,
    ref: string,
  }): CancelablePromise<DiscardedTopicsResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/topicer/projects/{project_id}/columns/{ref}/discarded-topics',
      path: {
        'project_id': projectId,
        'ref': ref,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Create discarded topics
   * Create discarded topics
   * @returns DiscardedTopicResponse Created
   * @throws ApiError
   */
  public static createDiscardedTopic({
    projectId,
    ref,
    requestBody,
  }: {
    projectId: string,
    ref: string,
    requestBody: DiscardedTopicRequest,
  }): CancelablePromise<DiscardedTopicResponse> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/topicer/projects/{project_id}/columns/{ref}/discarded-topics',
      path: {
        'project_id': projectId,
        'ref': ref,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Delete discarded topics
   * Delete discarded topics
   * @returns void
   * @throws ApiError
   */
  public static deleteDiscardedTopics({
    projectId,
    ref,
    id,
  }: {
    projectId: string,
    ref: string,
    id: string,
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/topicer/projects/{project_id}/columns/{ref}/discarded-topics/{id}',
      path: {
        'project_id': projectId,
        'ref': ref,
        'id': id,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Dummy List
   * Does nothing. It exists only to to force the following entities to be present in the generated OpenAPI specs.
   * @returns TopicerDummyResponse OK
   * @throws ApiError
   */
  public static topicerDummy(): CancelablePromise<TopicerDummyResponse> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/topicer/dummy',
      errors: {
        422: `Unprocessable Entity`,
      },
    });
  }
}

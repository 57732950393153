/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type CategoricalTextDistributionInsightElementUpdateRequest = {
  kind?: 'CategoricalTextDistributionInsight';
  order_id?: number;
  visible_left?: CategoricalTextDistributionInsightElementUpdateRequest.visible_left;
};
export namespace CategoricalTextDistributionInsightElementUpdateRequest {
  export enum visible_left {
    CATEGORICAL_TEXT_TREEMAP = 'categorical_text_treemap',
    CATEGORICAL_TEXT_BAR = 'categorical_text_bar',
  }
}

